import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
	obs: any;
	
	constructor() {
		// console.log('Inicio el dashboard')
		this.obs = new Observable(subscriber => {
			subscriber.next(1);
			subscriber.next(2);
			subscriber.next(3);
			setTimeout(() => {
			  subscriber.next(4);
			  subscriber.complete();
			}, 1000);
		  });
	}
	
	ngOnInit(): void {
		this.obsFunction()
	}
	
	obsFunction(){
		// console.log('just before subscribe');
		// this.obs.subscribe({
		// 	next(x:any) { console.log('got value ' + x); },
		// 	error(err: any) { console.error('something wrong occurred: ' + err); },
		// 	complete() { console.log('done'); }
		// });
		// console.log('just after subscribe');
	}
}
