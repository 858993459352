import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FactoringService } from 'app/core/services/factoring/factoring.service';

@Component({
  selector: 'app-perfiles-modal',
  templateUrl: './perfiles-modal.component.html',
//   styleUrls: ['./perfiles-modal.component.css']
})
export class PerfilesModalComponent implements OnInit {

    @Input() fila: any;
    @Input() perfilesSistemas: any[] = [];
    @Input() sistemas: any[] = [];
    perfilPorSistema: any;

    constructor(
        public activeModal: NgbActiveModal
    ) { }

    ngOnInit() {
        this.perfilPorSistema = this.obtenerPerfilPorSistema();
    }

    obtenerPerfilPorSistema() {
        let nueva: any[] = [];
        for (let index = 0; index < this.sistemas.length; index++) {
            let dict = {}
            dict['sistema'] = this.sistemas[index].descripcion;
            if (this.fila.perfil.length > 0) {
                this.perfilesSistemas[index].forEach(element => {
                    this.fila.perfil.forEach(perfil => {
                        if (perfil === element.id) {
                            dict['perfil'] = element.descripcion
                        }
                    });
                });
                if (!dict['perfil']) {
                    dict['perfil'] = '-'
                }
            } else {
                dict['perfil'] = '-'
            }
            nueva.push(dict);
        }
        return nueva;
    }

}
