import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'temToTea'
})
export class TemToTeaPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    
    let amount = 0;
		let val = 1 + (value / 100);
		amount = Math.pow(val, 12) - 1;
		amount *= 100;
		console.log(amount);
		return amount.toFixed(2)

  }

}
