import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	faFileDownload, 
	faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import {
	trigger,
	state,
	style,
	animate,
	transition
	} from '@angular/animations';	
import { FormBuilder, FormGroup } from '@angular/forms';
	
@Component({
	selector: 'app-upload-files',
	templateUrl: './upload-files.component.html',
	styleUrls: ['./upload-files.component.css'],
	animations: [
		trigger('loadingAnimation', [
			state('close', style({
			  opacity: 0,
			  display: 'none'
			})),
			state('open', style({
			  opacity: 1,
			  display: 'flex'
			})),
			transition('close <=> open', animate(300)),
		])
	  ]
})
export class UploadFilesComponent implements OnInit {
	faFileDownload     = faFileDownload;
	faCloudUploadAlt   = faCloudUploadAlt;
    documentoContext: any = {};
	docs               : any[];
	uploadForm         : FormGroup;
	@Input() set documentos(docs){
		if(docs){
			this.docs = docs.map((doc)=>{
				doc.loading = false
				return doc
			})
		}
	}
	@Input() readonly    : boolean = false;
	@Input() enableVerify: boolean = false;
	modelo               : boolean = false;
	@Output() fileSelected: EventEmitter<any> = new EventEmitter<any>();
	@Output() docVerify: EventEmitter<any> = new EventEmitter<any>();

	
		constructor(private formBuilder: FormBuilder) { }
	
	ngOnInit(): void {
	}
	
	verifyChange(event,doc,index){
		doc.loading = true
		this.docVerify.emit({doc: doc, index:index,value:event})
	}

	asyncEnd({doc,index}){
		doc.loading = false
	}


    uploadDoc(item){
        this.documentoContext = item;
        var fileSelect = document.createElement("input"); 
        fileSelect.setAttribute("id", "fielSelect"); 
        fileSelect.setAttribute("type", "file"); 
        fileSelect.setAttribute("name", "profile"); 
        fileSelect.setAttribute("style", "display: none;");
        let formularioDetalles = document.getElementById("form_upload")
        formularioDetalles.appendChild(fileSelect)
        document.getElementById("fielSelect").click()
        document.getElementById("fielSelect").addEventListener('change', (event) => {
            this.onFileSelect(event)
            formularioDetalles.removeChild(fileSelect)
        });
    }	
	

	onFileSelect(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			const formData = new FormData();
			formData.append('adjunto', file);
			formData.append('documento', this.documentoContext.id);
			this.fileSelected.emit(formData)
		}
	}	

	downloadDoc({adjunto}){
        let urlDoc = adjunto
        var win = window.open(urlDoc, '_blank');
        win.focus();
	}

	openDocument({adjunto}){
        let urlDoc = adjunto;
        console.log(adjunto);
        var win = window.open(urlDoc, '_blank');
        win.focus();
    }
}
