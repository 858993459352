import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms"

export const PasswordMath: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  const password = control.get("newPassword")
  const confirmarPassword = control.get("confirmPassword")

  return password.value === confirmarPassword.value
    ? null
    : { passwordMath: true }
}