import { NgModule } from "@angular/core";
import { EnteroPositivoDirective } from "./entero-positivo.directive";
import { NumeroDecimalDirective } from "./numero_decimal.directiva";
@NgModule({
    declarations: [
        EnteroPositivoDirective,
        NumeroDecimalDirective,
    ],
    imports: [

    ],
    exports: [
        EnteroPositivoDirective,
        NumeroDecimalDirective,
    ],
    providers: [
    ]
})

export class DirectivasModule { }
