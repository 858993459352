import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersCrudComponent } from './pages/users-crud/users-crud.component';
import { NgxSpinnerModule } from "ngx-spinner";

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

import { SharedModule } from '../../shared/shared.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
// import { ParametrosContrasenaComponent } from './modals/parametros-contraseña/parametros-contraseña.component';

// Modals
import { PerfilesModalComponent } from './modals/perfiles-modal/perfiles-modal.component';
import { ManageParametrosContrasenaModalComponent } from './pages/manage-parametros-contraseña-modal/manage-parametros-contraseña-modal.component';

import { RecaptchaModule } from 'ng-recaptcha';
import { ParametrosContrasenaComponent } from './pages/parametros-contrasena/parametros-contrasena.component';
import { DirectivasModule } from 'app/core/directivas/directivas.module';

@NgModule({
    declarations: [
        LoginComponent,
        UsersCrudComponent,
        ChangePasswordComponent,
        PerfilesModalComponent,
        // ParametrosContrasenaComponent,
        ManageParametrosContrasenaModalComponent,
        ParametrosContrasenaComponent
    ],
    imports: [
        DirectivasModule,
        JwBootstrapSwitchNg2Module,
        NgxSpinnerModule,
        FormsModule,
        CommonModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        NgxSkeletonLoaderModule,
        NgSelectModule,
        SharedModule,
        RecaptchaModule,
        UiSwitchModule.forRoot({
            size: 'medium',
            color: '#3e50b4',
            checkedTextColor: "#ffffff",
            uncheckedTextColor: "#ffffff",
            defaultBgColor: '#6c757d',
            defaultBoColor: '#3e50b4',
            checkedLabel: 'Si ',
            uncheckedLabel: 'No'
        }),
    ]
})
export class AuthModule { }
