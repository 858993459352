import { Component, OnInit, SimpleChanges} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { PerfilesModalComponent } from '../../modals/perfiles-modal/perfiles-modal.component';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import * as $ from 'jquery';

declare var require: any;

@Component({
    selector: 'app-users-crud',
    templateUrl: './users-crud.component.html',
    styleUrls: ['./users-crud.component.css']
})
export class UsersCrudComponent implements OnInit {
    public formulario: FormGroup;
    public columnDefs: any[];
    public rowData: any[] = [];
    public closeResult: string = '';
    public modalCreate: boolean = true;
    public searchText: string = '';
    public tableData: any[];
    public paginationData: any;
    public editableUser: any;
    public docsUser: any;
    public perfiles;
    public email;
    public estados: any[];
    public clientes;
    public loadingTable: boolean = false;
    public formReady: boolean = false;
    public paginationPage: number = 0;
    public userToDelete: any;
    public searchResults: boolean = false;
    public pageTableActual: number;
    public pageTableTotal: number;
    public pageTablePerPages: number = 10;
    public pageTablePages: number[];
    public sistemas;
    public perfilesSistemas;

    cliente: any;
    public adjunto: any;
    public file: any;
    public url: any;
    public inputPassword: string = 'pass';
    public empresaRequired: boolean = false;
    public telefonoRequired: boolean = false;
    public active: number = 1;

    constructor(
        public formbuild: FormBuilder,
        public modalService: NgbModal,
        public http: HttpClient,
        public spinner: NgxSpinnerService,
        public auth: AuthService,
        private clienteService: ClientesService,
        private factoringService: FactoringService,
    ) {

        let usuarios = this.auth.obtenerUsuarios()
        let perfiles = this.auth.obtenerPerfiles()
        let sistemas = this.auth.obtenerSistemas()
        
        Promise.all([
            usuarios,
            perfiles,
            sistemas]).then((res: any[]) => {
                let usuarios = res[0]
                let filter = res[1]['results'];
                let filterEmail = res[0]['results'];

                this.perfiles = filter.sort(function (a, b) {
                    if (a.descripcion > b.descripcion) {
                        return 1;
                    }
                    if (a.descripcion < b.descripcion) {
                        return -1;
                    }
                    return 0;
                });

                this.email = filterEmail.sort(function (a, b) {
                    if (a.email > b.email) {
                        return 1;
                    }
                    if (a.descripcion < b.descripcion) {
                        return -1;
                    }
                    return 0;
                });
                this.estados = [
                    { id: true, name: 'Activo' },
                    { id: false, name: 'Inactivo' },
                    //    { id: false, name: 'Solicitado' }
                ]
                this.sistemas = res[2]['results'];
                this.perfilesSistemas = [];
                this.sistemas.forEach(sistema => {
                    let perfilesXSistema = []
                    perfilesXSistema.push({ id: '', descripcion: '-' })
                    filter.forEach(perfil => {
                        if (sistema.id === perfil.sistema) {
                            perfilesXSistema.push(perfil)
                        }
                    });
                    this.perfilesSistemas.push(perfilesXSistema)
                });
                this.formReady = true
                this.initVars();
                this.loadTable(usuarios);
            })
    }

    ngOnInit() {
        
        // this.obtenerClientes();
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.formulario.get('documentos').setValue(file)
            this.url = URL.createObjectURL(file);
            this.adjunto = file.name
        }
    }

    verAdjunto(url) {
        if(this.modalCreate){
            let link = document.createElement('a');
            link.href = url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            link.setAttribute('download', this.adjunto);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
        }else{
            this.auth.getFile(url)
        }
    }

    selectFile() {
        document.getElementById("documentos").click();
    }
    async deleteAdjunto() {
        this.adjunto = null;
    }

    obtenerUsers() {
        let usuarios = this.auth.obtenerUsuarios()
        let perfiles = this.auth.obtenerPerfiles()
        Promise.all([
            usuarios,
            perfiles]).then((res: any[]) => {
                let usuarios = res[0]
                this.perfiles = res[1]['results']
                this.estados = [
                    { id: true, name: 'Activo' },
                    { id: false, name: 'Inactivo' },
                    // { id: false, name: 'Solicitado' }
                ]
                this.formReady = true
                this.initVars();
                this.loadTable(usuarios);

            });
    }

    obtenerClientes() {
        this.clienteService.obtenerClientes(1, 1000)
            .then(res => {
                this.clientes = res['results'];
            })
            .catch(error => {

            });
    }

    initVars() {
        this.columnDefs = [
            {
                headerName: 'Id',
                field: 'id',
                sortable: true
            },
            {
                headerName: 'Nombre',
                field: 'nombre',
                sortable: true,
                class: "text-center",
                filterable: true,
                filterProp: "nombre",
                filterInput: true,
            },
            {
                headerName: 'Apellido',
                field: 'apellido',
                sortable: true,
                class: "text-center",
                filterable: true,
                filterProp: "apellido",
                filterInput: true,
            },
            {
                headerName: 'Email',
                field: 'email',
                sortable: true,
                class: "text-center",
                filterable: true,
                filterProp: "email",
                filterInput: true,
            },
            {
                headerName: 'Estado',
                field: 'estado',
                pipe: 'statusBool',
                sortable: true,
                class: "text-center",
                filterable: true,
                filterProp: "estado",
                filterInput: true,
            },
            {
                headerName: 'Puesto',
                field: 'puesto',
                sortable: true
            },
            // {
            //     headerName: 'Perfil', 
            //     field: 'perfil_descripcion',
            //     sortable: true
            // },
            {
                headerName: 'Perfil',
                field: 'perfil_descripcion',
                // filterProp: 'perfil',
                // sortable: true,
                class: 'text-center',
                // filterable: true,
                // filterSelectItems: this.perfiles,
                filterItemsProps: {
                    value: 'id',
                    label: 'descripcion'
                },
                pipe: 'actionable',
                text: 'Ver',
            },
            {
                headerName: 'Fecha de creación',
                pipe: 'date',
                field: 'fecha_creacion',
                sortable: true,
                class: 'text-center'
            },
        ];
    }

    /**
     * Inicializa la tabla con datos y paginacion
     */
    loadTable(data) {
        this.rowData = data.results
        this.pageTableActual = data.page_number
        this.pageTableTotal = data.count
        this.pageTablePerPages = data.per_page
        this.pageTablePages = []
        for (let index = 0; index < data.num_pages; index++) {
            this.pageTablePages.push(index)
        }
    }

    goToPage({ page, per_page, filtros } = {
        page: this.pageTableActual, per_page: this.pageTablePerPages, filtros: {
            nombre: "",
            apellido: "",
            email: "",
            estado: ""
        }

    }) {
        if (filtros.nombre || filtros.apellido || filtros.email || filtros.estado) {
            this.auth
                .obtenerNombre(
                    page,
                    per_page,
                    filtros.nombre ? filtros.nombre : "",
                    filtros.apellido ? filtros.apellido : "",
                    filtros.email ? filtros.email : "",
                    filtros.estado ? filtros.estado : "",
                )
                .then((res: any) => {
                    this.loadTable(res);
                });

        } else {


            let request;
            request = this.auth.obtenerUsuarios(page, per_page, filtros['perfil'])
            request.then((res) => {
                this.loadTable(res)
            })
        }

    }

    async generatePassword() {
        const mayusculas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        const minusculas = 'abcdefghijklmnopqrstuvwxyz'
        const simbolos = '\.\,\;\:\-\_\¡\!\¿\?\#\$\%\+'
        const numeros = '0123456789'
        const todos = mayusculas + minusculas + simbolos + numeros

        let parametros = await this.auth.obtenerParametrosContrasena(1, 100)
        parametros = parametros["results"][0]
        let password = ''

        while ((password.match(/[A-Z]/g) || []).length <= parametros['mayusculas_minimas']) {
            password += mayusculas.charAt(Math.floor(Math.random() * mayusculas.length))
        }

        while ((password.match(/[a-z]/g) || []).length <= parametros['minusculas_minimas']) {
            password += minusculas.charAt(Math.floor(Math.random() * minusculas.length))
        }

        while ((password.match(/[\.\,\;\:\-\_\¡\!\¿\?\#\$\%\+]/g) || []).length <= parametros['simbolos_minimos']) {
            password += simbolos.charAt(Math.floor(Math.random() * simbolos.length))
        }

        while ((password.match(/[0-9]/g) || []).length <= parametros['numeros_minimos']) {
            password += numeros.charAt(Math.floor(Math.random() * numeros.length))
        }

        while (password.length <= parametros['longitud_minima']) {
            password += todos.charAt(Math.floor(Math.random() * todos.length))
        }
        password = password.split('').sort((a, b) => 0.5 - Math.random()).join('');
        this.formulario.controls['password'].setValue(password);
        return password;
    }

    copyToClipboard() {
        var textArea = document.createElement("textarea");
        textArea.value = this.formulario.get('password').value;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
           
        }

        document.body.removeChild(textArea);
    }

    closeModal(){
        this.active = 1
        this.empresaRequired = false
        this.telefonoRequired = false
        this.inputPassword = 'pass'
        this.modalService.dismissAll()
    }

    crearActualizarModal(){
        if (this.formulario.valid) {
            const formData = new FormData();
            formData.append('documentos', this.formulario.get('documentos').value);
            formData.append('nombre', this.formulario.get('nombre').value);
            formData.append('apellido', this.formulario.get('apellido').value);
            formData.append('email', this.formulario.get('email').value.toLowerCase());
            formData.append('password', this.formulario.get('password').value);

            for (var i = 0; i < this.formulario.get('perfil').value.length; i++) {
                if (this.formulario.get('perfil').value[i] !== "") {
                    formData.append('perfil', this.formulario.get('perfil').value[i]);
                }
            }

            formData.append('telefono', this.formulario.get('telefono').value);
            formData.append('nombre_empresa', this.formulario.get('nombre_empresa').value);
            formData.append('estado', this.formulario.get('estado').value);
            formData.append('ruc_empresa', this.formulario.get('ruc_empresa').value);
            formData.append('cliente', this.formulario.get('cliente').value);
            formData.append('codigo_sf', this.formulario.get('codigo_sf').value);
            formData.append('sucursal', this.formulario.get('sucursal').value);
            formData.append('dni', this.formulario.get('dni').value);
            formData.append('puesto', this.formulario.get('puesto').value);
            formData.append('restablecer_password', this.formulario.get('restablecer_password').value);
            let value = this.formulario.value
            if (!this.modalCreate) {
                value.id = this.editableUser.id
                this.uploadUser(formData, value.id)
            } else {
                if(this.formulario.get('dni_file') !== null){
                    formData.append('dni_file', this.formulario.get('dni_file').value);
                }
                if(this.formulario.get('company_file') !== null){
                    formData.append('company_file', this.formulario.get('company_file').value);
                }
                if(this.formulario.get('ruc_file') !== null){
                    formData.append('ruc_file', this.formulario.get('ruc_file').value);
                }
                this.postUser(formData)
            }
            if (this.cliente) {
                value['cliente_nombre'] = this.cliente.nombre;
            }

            if (value.password == '') {
                delete value.password
            }
            for (let index = 0; index < value.perfil.length; index++) {
                if (value.perfil[index] === '') {
                    value.perfil.splice(index, 1);
                    index--;
                }
            }
        }
    }

    initForm() {
        if (this.modalCreate) {
            this.formulario = this.formbuild.group({
                nombre: ['', [Validators.required, Validators.minLength(2)]],
                apellido: ['', [Validators.required, Validators.minLength(2)]],
                email: ['', [Validators.required, Validators.minLength(5), Validators.email]],
                password: ['', [Validators.required, Validators.minLength(8)]],
                perfil: this.formbuild.array([], this.AtLeastOneFieldValidator),
                documentos:  [''],
                nombre_empresa: this.empresaRequired ? ['', [Validators.required]] : [''],
                estado: ['', [Validators.required]],
                ruc_empresa: this.empresaRequired ? ['', [Validators.required, Validators.pattern('^([0-9]*)$')]] : [''],
                cliente: [''],
                codigo_sf: [''],
                sucursal: [''],
                company_file: [''],
                ruc_file: [''],
                dni_file: [''],
                dni: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^([0-9]*)$')]],
                telefono: this.telefonoRequired ? ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^([0-9]*)$')]]
                                                : ['', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^([0-9]*)$')]],                puesto: [''],
                restablecer_password: [false, []]
            })
            let docsUsers = this.auth.obtenerDocumentosDescripcion()
            Promise.all([docsUsers]).then((res: any) => {
                const [docsUsersResponse] = res;
                this.docsUser = [...docsUsersResponse];
            })
        } else {
            this.formulario = this.formbuild.group({
                nombre: ['', [Validators.required, Validators.minLength(2)]],
                apellido: ['', [Validators.required, Validators.minLength(2)]],
                email: ['', [Validators.required, Validators.minLength(5), Validators.email]],
                password: ['', [Validators.minLength(8)]],
                perfil: this.formbuild.array([], this.AtLeastOneFieldValidator),
                documentos: [''],
                nombre_empresa: this.empresaRequired ? ['', [Validators.required]] : [''],
                ruc_empresa: this.empresaRequired ? ['', [Validators.required, Validators.pattern('^([0-9]*)$')]] : [''],
                estado: ['', [Validators.required]],
                cliente: [''],
                codigo_sf: [''],
                sucursal: [''],
                puesto: [''],
                dni: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(8), Validators.pattern('^([0-9]*)$')]],
                telefono: this.telefonoRequired ? ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^([0-9]*)$')]]
                                                : ['', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^([0-9]*)$')]],
                restablecer_password: [false, []]
            })

        }

        this.sistemas.forEach(element => {
            this.addPerfil();
        });
    }

    AtLeastOneFieldValidator(array: FormArray): { [key: string]: any } {
        let isAtLeastOne = false;
        if (array && array.controls) {
            for (const control in array.controls) {
                if (array.controls.hasOwnProperty(control) && array.controls[control].valid && array.controls[control].value) {
                    isAtLeastOne = true;
                    break;
                }
            }
        }
        return isAtLeastOne ? null : { 'required': true };
    }

    get perfil() {
        return this.formulario.get('perfil') as FormArray;
    }

    createItemFormArray(): FormControl {
        return new FormControl('');
        // return new FormControl('', Validators.required);
    }

    addPerfil() {
        this.perfil.push(this.createItemFormArray());
    }

    removePerfil(index: number) {
        this.perfil.removeAt(index);
    }

    openPerfiles(row) {
        const modalRef = this.modalService.open(PerfilesModalComponent, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'xl'
        });
        modalRef.componentInstance.fila = row;
        modalRef.componentInstance.perfilesSistemas = this.perfilesSistemas;
        modalRef.componentInstance.sistemas = this.sistemas;
        modalRef.result.then(res => {

        });
    }

    setUser(e) {
        this.cliente = e;
    }

    openModal(content) {
        this.initForm()
        this.adjunto = null;
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'lg',
            backdrop: 'static'
        }).result.then((result) => {
            this.formulario.reset()
        }, (reason) => {
            this.formulario.reset()
        });
    }

    cambiarSelect(event, type){
        if(type === 'Portal'){
            if(event.descripcion !== '-' && event.descripcion !== 'Admin Portal'){
                this.empresaRequired = true
                this.telefonoRequired = true
                this.formulario.controls["ruc_empresa"].setValidators([Validators.required,Validators.pattern('^([0-9]*)$')]);
                this.formulario.controls["nombre_empresa"].setValidators(Validators.required);
                this.formulario.controls["telefono"].setValidators([Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^([0-9]*)$')]);
            }else{
                this.empresaRequired = false
                this.telefonoRequired = false
                this.formulario.controls["ruc_empresa"].clearValidators();
                this.formulario.controls["nombre_empresa"].clearValidators();
                this.formulario.controls["telefono"].setValidators([Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^([0-9]*)$')]);
            }
        }
    }

    tableEditEvent(row: any, modalContent: any) {
        this.modalCreate = false;
        let users = this.auth.obtenerUsuario(row.id)
        let docsUsers = this.auth.obtenerDocumentosDescripcion()
        Promise.all([users, docsUsers]).then((res: any) => {
            const [usersResponse, docsUsersResponse] = res;
            this.docsUser = [...docsUsersResponse];
            usersResponse.documentos.forEach(user => {
                this.docsUser.forEach(doc => {
                    if(user.descripcion === doc.descripcion){
                        doc['link'] = user.adjunto
                        doc['id_doc'] = user.id
                    }
                });
            });
            usersResponse.documentos.forEach(user => {
                this.docsUser.forEach(doc => {
                    if(user.descripcion === doc.descripcion){
                        doc['link'] = user.adjunto
                        doc['id_doc'] = user.id
                    }
                });
            });
            this.openModal(modalContent);
            let perfiles: Array<any> = [];
            if (usersResponse.perfil.length > 0) {
                this.perfilesSistemas.forEach(sistema => {
                    let bool: boolean = true;
                    sistema.forEach(perfil => {
                        usersResponse.perfil.forEach(idPerfil => {
                            if (perfil.id === idPerfil) {
                                if(perfil.sistema_descripcion === 'Portal' && perfil.descripcion !== 'Admin Portal'){
                                    this.empresaRequired = true
                                    this.telefonoRequired = true
                                }
                                perfiles.push(idPerfil);
                                bool = false;
                            }
                        })
                    });
                    if (bool) {
                        perfiles.push('');
                    }
                });
            } else {
                perfiles = Array(this.sistemas.length).fill('')
            }
            this.formulario.setValue({
                nombre: usersResponse.nombre,
                apellido: usersResponse.apellido,
                email: usersResponse.email.toLowerCase(),
                telefono: usersResponse.telefono,
                nombre_empresa: usersResponse.nombre_empresa,
                documentos: usersResponse.documentos,
                password: '',
                ruc_empresa: usersResponse.ruc_empresa ? usersResponse.ruc_empresa : '',
                perfil: perfiles,
                estado: usersResponse.estado,
                restablecer_password: usersResponse.restablecer_password,
                cliente: usersResponse.cliente ? usersResponse.cliente : '',
                codigo_sf: usersResponse.codigo_sf ? usersResponse.codigo_sf : '',
                sucursal: usersResponse.sucursal ? usersResponse.sucursal : '',
                dni: usersResponse.dni ? usersResponse.dni : '',
                puesto: usersResponse.puesto ? usersResponse.puesto : '',
            });
            this.editableUser = usersResponse;
        })
        .catch(error => {
            
        });
    }

    selectFileDocs(e){
        document.getElementById(e).click();
    }

    upload(e, type, id_doc, name){
        if(!this.modalCreate){
            this.auth.uploadDocUsers(e.target.files[0], type, this.editableUser.id, id_doc).then((res: any) => {
                let data = res;
                this.docsUser.forEach(doc => {
                    if(data.descripcion === doc.descripcion){
                        doc['link'] = data.documento
                        doc['id_doc'] = data.id
                    }
                });
                this.cleanr(name)
            })
        }else{
            switch (type) {
                case 'Copia Literal / Vigencia de Poder':
                    this.formulario.get('company_file').setValue(e.target.files[0])
                    break;
                case 'DNI de Intervinientes':
                    this.formulario.get('dni_file').setValue(e.target.files[0])
                    break;
                case 'Copia Ficha RUC':
                    this.formulario.get('ruc_file').setValue(e.target.files[0])
                    break;
                default:
                    break;
            }
            let url = URL.createObjectURL(e.target.files[0]);
            this.adjunto =  e.target.files[0].name
            // console.log(this.formulario)
            this.docsUser.forEach(doc => {
                if(type === doc.descripcion){
                    doc['link'] = url
                }
            });
            this.cleanr(name)
        }
    }

    cleanr(name){
        $('#'+name).val('')
    }

    tableReadEvent(row: any, modalContent: any) {
        this.modalCreate = false
        this.openModal(modalContent);
    }

    tableDeleteEvent(row: any, modalContent: any) {
        this.userToDelete = row;
        this.modalService.open(modalContent, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'md'
        }).result.then((result) => {
            if (result) {
                this.deleteUser(row)
            }
        }, (reason) => {

        });
    }

    createUserButtonClicked(contentModal: any) {
        this.modalCreate = true;
        this.openModal(contentModal);
    }

    postUser(user) {
        this.auth.postUsers(user).then((res) => {
            this.adjunto = null;
            this.goToPage()
            this.modalCreate = false
            this.closeModal()
        })
    }

    uploadUser(user, id) {
        this.auth.uploadUsers(user, id).then((res) => {
            this.adjunto = null;
            this.goToPage()
            this.modalCreate = true
            this.editableUser = null
            this.closeModal()
        })
    }

    deleteUser(user) {
        this.auth.deleteUsers(user.id).then((res) => {
            this.goToPage()
        })
    }

    cambiarInput(input){
        if(input === 'text'){
            this.inputPassword = 'pass'
        }else{
            this.inputPassword = 'text'
        }
    }
}