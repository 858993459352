import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../toast/toast.service';
import { AppService } from 'app/app.service';
import { forkJoin, from, Observable, Subject, throwError } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class FactoringService {
    private apiUrl: string = '';
    public operacionAll: string = 'factoring/operaciones/';
    public operacionDocumentos: string = 'factoring/operaciones/documentos/';
    public operacionDetalle: string = 'factoring/operaciones/detalle/';
    public clientes: string = 'factoring/operaciones/clientes/';
    public bandeja: string = 'factoring/operaciones/bandeja/';
    public operacionesComite: string = 'factoring/operaciones/bandeja/comite';
    public derivar: string = 'factoring/operaciones/derivar/';
    public beneficiarios: string = 'factoring/beneficiarios/';
    public documentosPorEstacion: string = 'factoring/documentos/estacion/';
    public comites: string = 'factoring/comites/';
    public denegarOperacionURL: string = 'factoring/operaciones/denegar/';
    public aprobarOperacionURL: string = 'factoring/operaciones/aprobar/comite/';
    public operacionSugerenciasLista: string = 'factoring/sugerencias/responsable/';
    public operacionSugerencias: string = 'factoring/sugerencias/';
    public operacionSugerenciasBandeja: string = 'factoring/sugerencias/bandeja';
    public devolver: string = 'factoring/operaciones/devolver/';
    public reenviar: string = 'factoring/operaciones/reenviar/';
    public importarXml: string = 'factoring/operaciones/detalle/xml/';
    public detalles: string = 'factoring/operaciones/detalle/';
    public responsables: string = 'factoring/responsables/';
    public liquidacionesModificar: string = 'factoring/liquidaciones/modificarmontos/';
    public lineas: string = 'factoring/lineas/';
    public deudor: string = 'factoring/deudores/';
    public operacionResumenEndpoint: string = 'factoring/operaciones/resumen/';
    public sugerenciasPendientes: any[] = [];
    private changeOperacion = new Subject<void>();
    public changeOperacionEnd$ = this.changeOperacion.asObservable();
    public monedas: string = "factoring/monedas/";
    public movimientos: string = "factoring/operaciones/movimientos/estaciones/";
    public validacionUbigeo: string = "factoring/";

    public adjuntos: string = "/factoring/prorrogas/adjuntos/";

    constructor(
        public appService: AppService,
        public toast: ToastService,
        public spinner: NgxSpinnerService,
        public http: HttpClient,
        public auth: AuthService
    ) {
        this.apiUrl = this.appService.settings.API_base_url
    }


    cargarAdjunto(data: object) {
        const url = this.apiUrl + this.adjuntos;

        return new Promise((res, ref) => {
            this.spinnerOn();
            this.http.post(url, data).subscribe(
                (response) => {
                    console.log(response)
                    this.spinnerOff();
                    res(response);
                },
                (err) => {
                    this.spinnerOff();
                    ref(err);
                }
            );
        });
    }

    /**
     * Habilita el loader para request a la API
     */
    spinnerOn() {
        this.spinner.show()
    }

    /**
     * Desabilita el loader
     * @param mensaje Mensaje del toast
     * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
     */
    spinnerOff(mensaje: string = null, ok: boolean = true) {
        this.spinner.hide()
        this.appService.notifyMe(mensaje, ok)

        if (mensaje && ok)
            this.toast.success(mensaje)
        if (mensaje && !ok)
            this.toast.warning(mensaje)
    }

    /**
   * Mostros errores recibidos del servidor
   * @param error Error enviado del servidor
   */
    showErrors(error) {
        const err = error.error;

        for (const key in err) {
            if (Object.prototype.hasOwnProperty.call(err, key)) {
                const element = err[key];
                console.log(element);

                if (Array.isArray(element) && element.length) {
                    element.forEach(item => {
                        this.spinnerOff(item, false);
                    });
                } else {
                    this.spinnerOff(element, false);
                }

            }
        }
    }

    eventChangeOperation() {
        this.changeOperacion.next();
    }

    /**
     * 
     * Obtiene los clientes de un geneficiario registrado
     * @param beneficiario ID del cliente registrado
     * @param page Pagina del request
     * @param page_size Resultados por pagina
     * @param oficial_negocio ID del oficial de negocio asignado 
     * @param responsable ID del responsable del proceso
     * @param estacion ID de la estacion
     * @param estado ID del filtro de estado
     * @param fecha__desde Filtro de fecha DESDE
     * @param fecha__hasta Filtro de fecha HASTA
    */
    // obtenerBandeja(
    //     page           = 1,
    //     page_size      = 10,
    //     beneficiario   : any = '',
    //     oficial_negocio: any = '',
    //     responsable    : any = '',
    //     estado         : any = '',
    //     estacion       : any = '',
    //     fecha__desde   : any = '',
    //     fecha__hasta   : any = '',
    //     comite         : any = '',
    //     analista_riesgo         : any = '',
    // ){
    //     const url = this.apiUrl+
    //         this.bandeja+
    //         `?beneficiario=${beneficiario}`+
    //         `&page=${page}`+
    //         `&page_size=${page_size}`+
    //         `&oficial_negocio=${oficial_negocio}`+
    //         `&responsable=${responsable}`+
    //         `&estado=${estado}`+
    //         `&estacion=${estacion}`+
    //         `&fecha__gte=${fecha__desde}`+
    //         `&fecha__lte=${fecha__hasta}`+
    //         `&analista_riesgo=${analista_riesgo}`+
    //         `&comite=${comite}`;

    //     return new Promise((res,ref)=>{
    //         this.spinnerOn()
    //         this.http.get(url)
    //             .subscribe((response)=>{
    //                 this.spinnerOff()
    //                 res(response)
    //             }, (err)=>{
    //                 ref(err)
    //             })
    //     })        
    // }         



    /**
     * Obtener lista de contactos de un beneficiario
     * @param beneficiario ID del beneficiario
     */
    obtenerContactos(beneficiario: number = null) {

        const idUsuario = (beneficiario) ? '?beneficiario=' + beneficiario : '';

        const URL = this.apiUrl + 'factoring/contactos/' + idUsuario;

        return new Promise((resolve, reject) => {
            this.spinnerOn()
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, (err) => {
                this.spinnerOff()
                reject(err);
            });

        });

    }

    async getBeneficiarios(id: number = null) {

        const idUsuario = (id) ? id : '';

        const URL = this.apiUrl + 'factoring/beneficiarios/' + idUsuario;
        // const URL = this.apiUrl + this.clientes + `clientes/${idUsuario}/`;

        this.spinnerOn()
        return new Promise((resolve, reject) => {
            this.spinnerOff()
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                this.spinnerOff()
                reject(err);
            });

        });

    }

    async getDeudores(id: number = null) {

        const idUsuario = (id) ? id : '';

        const URL = this.apiUrl + 'factoring/deudores/' + idUsuario;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            this.spinnerOff();
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                this.spinnerOff();
                reject(err);
            });

        });

    }

    obtenerBeneficiario(beneficiarioId) {
        const URL = this.apiUrl + this.beneficiarios + `${beneficiarioId}/`;
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

    buscarBeneficiarios(ruc__icontains: any = '', page: number = 1, page_size: number = 10) {
        const URL = this.apiUrl +
            this.beneficiarios +
            `?ruc_nombre__icontains=${ruc__icontains}` +
            `&page=${page}` +
            `&page_size=${page_size}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

    buscarBeneficiariosObserver(ruc__icontains: any = '', page: number = 1, page_size: number = 10): Observable<any> {
        const URL = this.apiUrl +
            this.beneficiarios +
            `?ruc_nombre__icontains=${ruc__icontains}` +
            `&page=${page}` +
            `&page_size=${page_size}`;

        return this.http
            .get<any>(URL)
            .pipe(map(resp => {
                if (resp.Error) {
                    throwError(resp.Error);
                } else {
                    return resp.results;
                }
            })
            );
    }

    buscarDeudorObserver(ruc__icontains: any = '', page: number = 1, page_size: number = 10): Observable<any> {
        const URL = this.apiUrl +
            this.deudor +
            `?ruc_nombre__icontains=${ruc__icontains}` +
            `&page=${page}` +
            `&page_size=${page_size}`;

        return this.http
            .get<any>(URL)
            .pipe(map(resp => {
                if (resp.Error) {
                    throwError(resp.Error);
                } else {
                    return resp.results;
                }
            })
            );
    }



    /**
     * Guarda o actualiza una operacion, si recibe un ID envia una peticion PUT
     * @param data Objeto JSON del objeto a actualizar
     * @param id ID de la operacion a actualizar
     */
    guardarOperacion(data: any, id: any = null) {

        return new Promise((resolve, reject) => {
            let httpReques;
            let URL = this.apiUrl + 'factoring/operaciones/';
            if (!id) {
                httpReques = this.http.post(URL, data);
            } else {
                URL += `${id}/`
                httpReques = this.http.put(URL, data);
            }

            this.spinnerOn()
            httpReques.subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, (err) => {
                this.spinnerOff('Ocurrió un error durante el proceso : ' + err);
                reject(err);
            });

        });

    }


    /**
     * Actualiza los detalles de una operacion
     * @param id ID de la operacion que se desea actualizar
     * @param data Objeto JSON de la operacion
     */
    actualizarOperacion(data: any, id: number) {
        const URL = this.apiUrl + 'factoring/operaciones/' + id + '/';
        return new Promise((resolve, reject) => {

            const httpReques = this.http.patch(URL, data);
            this.spinnerOn()
            httpReques.subscribe(data => {
                this.spinnerOff('La operación fue grabada con exito!')
                resolve(data);
            }, (err) => {
                this.spinnerOff('Ocurrió un error durante el proceso :' + err)
                reject(err);
            });

        });

    }

    async operacion(id: number) {

        const URL = this.apiUrl + 'factoring/operaciones/' + id;

        return new Promise((resolve, reject) => {

            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });

    }

    async operacionResumen(id: number) {

        const URL = this.apiUrl + 'factoring/operaciones/resumen/' + id + '/';
        this.spinnerOn()
        return new Promise((resolve, reject) => {

            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                this.spinnerOff()
                reject(err);
            });

        });

    }

    async clienteRelaciones(id: number, page_size = 1000, page = 1) {

        const URL = this.apiUrl + 'clientes/relaciones/clientes/' + `?page_size=${page_size}&page=${page}&cliente=${id}`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {

            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                this.spinnerOff()
                reject(err);
            });

        });

    }

    /**
     * Retorna los documentos que estan asignados a casa estacion
     * @param estacion ID de la estacion cuyos documentos se quieren obtener
     * @param page Pagina que se desea visalizar
     * @param page_size Cantidad de registros por query
     */
    obtenerDocumentosEstacion(estacion: any = '', page: number = 1, page_size: number = 1000) {

        const URL = this.apiUrl +
            this.documentosPorEstacion +
            `?estacion=${estacion}` +
            `&page=${page}` +
            `&page_size=${page_size}`;

        this.spinnerOn();
        return new Promise((res, ref) => {
            this.http.get(URL)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    /**
     * Retorna los documentos adjuntos a una operacion en particular
     * @param operacion ID de la operacion cuyos documentos se quieren obtener
     */
    obtenerOperacionDocumentos(operacion: number) {
        this.spinnerOn();
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.operacionDocumentos}/?operacion=${operacion}&page=1&page_size=1000`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    obtenerDocumentosBeneficiarios(benefeciario: number) {
        this.spinnerOn();
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.beneficiarios}/documentos?beneficiario=${benefeciario}&page=1&page_size=1000`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    ref(err);
                    this.spinnerOff();
                })
        });
    }

    /**
     * Guarda un documento adjunto para determinado proceso de factoring
     * @param data Objeto JSON con la informacion del documento a guardar
     */
    guardarDocumentoOperacion(data: any) {
        this.spinnerOn();
        return new Promise((res, ref) => {
            this.http.post(`${this.apiUrl}${this.operacionDocumentos}`, data)
                .subscribe((response) => {
                    this.spinnerOff('El archivo fue adjuntado de manera exitosa');
                    res(response)
                }, (err) => {
                    this.spinnerOff('Ocurrió un error durante la carga del archivo', false);
                    ref(err)
                })
        })
    }

    /**
     * Actualiza el objeto de un documento en particular
     * @param id ID del documento a actualizar 
     * @param data Objeto del documento a actualizar
     */
    actualizarDocumentoOperacion(id: number, data: any, state: boolean) {
        this.spinnerOn();
        delete data.adjunto
        data.verificado = state
        return new Promise((res, ref) => {
            this.http.put(`${this.apiUrl}${this.operacionDocumentos}${id}/`, data)
                .subscribe((response) => {
                    this.spinnerOff('El archivo fue verificado de manera exitosa');
                    res(response)
                }, (err) => {
                    this.spinnerOff('Ocurrió un error durante la verificación del archivo', false);
                    ref(err)
                })
        })
    }


    /**
     * Retorna detalles y documentos adjuntos de un determinado proceso
     * @param operacion Id de operacion a obtener 
     */
    obtenerDetalles(operacion: number, page_size = 1000) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.operacionDetalle}?operacion=${operacion}&page_size=${page_size}`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    /**
     * Retorna el detalle de una operacion
     * @param detalle Id del detalle a obtener
     */
    obtenerDetalle(detalle: number) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.detalles}${detalle}/`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    /**
     * El metodo guarda un documento de proceso de factoring,
     * si recibe un ID la solicitud se ejecuta por el metodo PUT,
     * de no recibir un ID la solicitud se ejecuta por el metodo POST
     * @param detalle Objeto JSON del documento o detalle de proceso a guardar o crear
     * @param id ID opcional en caso de ser un UPDATE y no un cREATE
     */
    guardarDetalle(detalle: any, id: number = null) {
        this.spinnerOn();
        return new Promise((res, ref) => {
            let request;

            if (id) {
                // Cuando el metodo recibe un ID crea un request tipo PUt para actualizar el objeto del ID
                request = this.http.put(`${this.apiUrl}${this.operacionDetalle}${id}/`, detalle)
            } else {
                // Cuando NO recibe un ID crea un request tipo POST para crear un nuevo registro
                request = this.http.post(`${this.apiUrl}${this.operacionDetalle}`, detalle)
            }

            request
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    const error = (err.error['linea disponible']) ? err.error['linea disponible'] : err;
                    this.spinnerOff(error, false);
                    ref(err);
                });
        })
    }

    /**
     * Elimina un elemento de detalle de un proceso en particular
     * @param id ID del elemento de detalle a eliminar
     */
    eliminarDetalle(id: number) {
        return new Promise((res, ref) => {
            this.http.delete(`${this.apiUrl}${this.operacionDetalle}${id}/`)
                .subscribe((response) => {
                    this.spinnerOff('Eliminó el detalle correctamente');
                    res(response)
                }, (err) => {
                    this.spinnerOff('Ocurrió un error durante el procesamiento :' + err, false);
                    ref(err)
                })
        })
    }

    /**
     * 
     * @param data 
     */
    importarXmlDetalles(data) {
        this.spinnerOn();

        const url = `${this.apiUrl}${this.importarXml}`;

        return new Promise((res, ref) => {

            // data.forEach(element => {
            //     this.http.post(`${this.apiUrl}${this.importarXml}`,element)
            //     .subscribe((response)=>{
            //         this.spinnerOff('El archivo fue adjuntado de manera exitosa');
            //         res(response);
            //     }, (err)=>{
            //         const errorFormat = 'Ocurrió un error, el formato del documento no es el adecuado';
            //         const error = (err.error['linea disponible']) ? err.error['linea disponible'] : errorFormat;
            //         this.spinnerOff(error,false);
            //         ref(err);
            //     });
            // });

            let arrayOfData = [];
            data.forEach(element => {
                arrayOfData.push(this.http.post(url, element));
            });

            forkJoin(arrayOfData).subscribe(response => {
                this.spinnerOff('El archivo fue adjuntado de manera exitosa');
                res(response);
            }, err => {
                const errorFormat = 'Ocurrió un error, el formato del documento no es el adecuado';
                const error = (err.error['linea disponible']) ? err.error['linea disponible'] : errorFormat;
                this.spinnerOff(error, false);
                ref(err);
            });

        });
    }

    /**
     * 
     * @param detalleId 
     * @param data 
     */
    importarPDF(detalleId, data) {
        this.spinnerOn();
        return new Promise((res, ref) => {
            this.http.patch(`${this.apiUrl}${this.detalles}${detalleId}/`, data)
                .subscribe((response) => {
                    this.spinnerOff('El archivo fue adjuntado de manera exitosa');
                    res(response);
                }, (err) => {
                    const errorFormat = 'Ocurrió un error, el formato del documento no es el adecuado';
                    const error = (err.error['linea disponible']) ? err.error['linea disponible'] : errorFormat;
                    this.spinnerOff(error, false);
                    ref(err);
                })
        });
    }

    /**
     * Deriva una operacion a la próxima estacion
     * @param id ID de la operacion a derivar
     */
    derivarOperacion(id: number) {
        return new Promise((res, ref) => {
            this.http.put(`${this.apiUrl}${this.derivar}${id}/`, null)
                .subscribe((response) => {
                    this.spinnerOff('La operación fue derivada correctamente');
                    res(response)
                }, (err) => {
                    this.spinnerOff(err.error.detail, false);
                    ref(err)
                })
        })
    }

    /**
     * Devuelve la operacion a la estacion especificada
     * @param id ID de la operacion a devolver
     * @param data estacion a la que se desea devolver.
     */
    devolverOperacion(id: number, data) {
        return new Promise((res, ref) => {
            this.http.patch(`${this.apiUrl}${this.devolver}${id}/`, data)
                .subscribe((response) => {
                    this.spinnerOff('Se la operación fue devuelta correctamente');
                    res(response)
                }, (err) => {
                    this.spinnerOff(err.error.detail, false);
                    ref(err)
                })
        });
    }

    /**
     * Reenvia la operacion a la estacion a la que fue devuelta
     * @param id ID de la operacion a devolver
     * @param data estacion a la que se desea reenviar la operacion
     */
    reenviarOperacion(id: number, data) {
        return new Promise((res, ref) => {
            this.http.patch(`${this.apiUrl}${this.reenviar}${id}/`, data)
                .subscribe((response) => {
                    this.spinnerOff('Se la operación fue reenviada correctamente');
                    res(response)
                }, (err) => {
                    this.spinnerOff(err.error.detail, false);
                    ref(err)
                })
        });
    }

    /**
     * Obtiene los comites activos
     * @param page Pagina a obtener
     * @param page_size Cantidad de resutlados por query
     */
    obtenerComites(page = 1, page_size = 100) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.comites}`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }


    /**
     * Metodo para denegar una operacion
     */
    denegarOperacion(operacion) {
        return new Promise((res, ref) => {
            this.http.put(`${this.apiUrl}${this.denegarOperacionURL}${operacion.id}/`, null)
                .subscribe((response) => {
                    this.spinnerOff('Se la operación fue denegada correctamente');
                    res(response)
                }, (err) => {
                    this.spinnerOff(err.error.detail, false);
                    ref(err)
                })
        })
    }

    /**
     * Metodo para denegar una operacion
     */
    aprobarOperacion(operacion) {
        return new Promise((res, ref) => {
            this.http.post(`${this.apiUrl}${this.aprobarOperacionURL}`, {
                operacion: operacion.id,
                responsable: this.auth.user.id
            })
                .subscribe((response) => {
                    this.spinnerOff('Se la operación fue aprobada correctamente');
                    res(response)
                }, (err) => {
                    this.spinnerOff(err.error.detail, false);
                    ref(err);
                })
        })
    }

    /**
     * Retorna los usuarios disponibles para solicitar sugerencias
     */
    listadoUsuariosSugerencias(operacion) {

        const URL = this.apiUrl +
            this.operacionSugerenciasLista +
            `?operacion=${operacion}&page_size=10000`;

        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(URL)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }


    /**
 * Retorna los usuarios disponibles para solicitar sugerencias
 */
    crearSolicitudsugerencia(operacion, idUsario) {

        const URL = this.apiUrl +
            this.operacionSugerencias;

        return new Promise((res, ref) => {
            this.http.post(URL, {
                operacion: operacion,
                responsable: idUsario,
            })
                .subscribe((response) => {
                    res(response)
                }, (err) => {
                    ref(err)
                })
        })
    }


    bandejaDeSugerencias(idUser, page_size = 1000) {
        const URL = this.apiUrl +

            this.operacionSugerenciasBandeja +
            `?responsable=${idUser}` +
            `&page_size=${page_size}`;

        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(URL)
                .subscribe((response: any) => {
                    this.spinnerOff();
                    console.log(response['results']);
                    this.sugerenciasPendientes = response['results'];
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    obtenerResponsables(rol, page = 1, page_size = 1000) {
        const URL = this.apiUrl +

            this.responsables +
            `?rol=${rol}` +
            `&page=${page}` +
            `&page_size=${page_size}`;

        this.spinnerOn()
        return new Promise((res, ref) => {
            return this.http.get(URL)
                .subscribe((response: any) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    /**
     * Retorna detalles y documentos adjuntos de un determinado proceso
     * @param operacion Id de operacion a obtener 
     */
    obtenerOperacion(operacion: number) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.operacionAll}${operacion}/`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    /**
     * Retorna detalles y documentos adjuntos de un determinado proceso
     * @param operacion Id de operacion a obtener 
     */
    eliminarOperacion(operacion: number) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.delete(`${this.apiUrl}${this.operacionAll}${operacion}/`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    modificarDatosLiquidacion(liquidacion: number, body) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.put(`${this.apiUrl}${this.liquidacionesModificar}${liquidacion}/`, body)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }


    /**
     * 
     * Obtiene los clientes de un geneficiario registrado
     * @param beneficiario ID del cliente registrado
     * @param page Pagina del request
     * @param page_size Resultados por pagina
     * @param oficial_negocio ID del oficial de negocio asignado 
     * @param responsable ID del responsable del proceso
     * @param estacion ID de la estacion
     * @param estado ID del filtro de estado
     * @param fecha__desde Filtro de fecha DESDE
     * @param fecha__hasta Filtro de fecha HASTA
    */
    obtenerBandeja(
        page = 1,
        page_size = 10,
        beneficiario: any = '',
        oficial_negocio: any = '',
        responsable: any = '',
        estado: any = '',
        estacion: any = '',
        fecha__desde: any = '',
        fecha__hasta: any = '',
        comite: any = '',
        analista_riesgo: any = '',
        oficial_negocio__nombre: any = '',
        monto__range: any = '',
        analista_de_operaciones: any = '',
    ) {
        const url = this.apiUrl +
            this.bandeja +
            `?beneficiario=${beneficiario}` +
            `&page=${page}` +
            `&page_size=${page_size}` +
            `&oficial_negocio=${oficial_negocio}` +
            `&responsable=${responsable}` +
            `&estado=${estado}` +
            `&estacion=${estacion}` +
            `&fecha__gte=${fecha__desde}` +
            `&fecha__lte=${fecha__hasta}` +
            `&analista_riesgo=${analista_riesgo}` +
            `&comite=${comite}` +
            `&oficial_negocio__nombre=${oficial_negocio__nombre}` +
            `&analista_de_operaciones=${analista_de_operaciones}` +
            `&monto__range=${monto__range}`;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    obtenerOperacionBandeja(operacion: number) {
        const url = this.apiUrl + this.bandeja + `${operacion}`;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    this.showErrors(err);
                    ref(err);
                })
        });
    }

    /**
 * 
 * Obtiene los clientes de un geneficiario registrado
 * @param beneficiario ID del cliente registrado
 * @param page Pagina del request
 * @param page_size Resultados por pagina
 * @param oficial_negocio ID del oficial de negocio asignado 
 * @param responsable ID del responsable del proceso
 * @param estacion ID de la estacion
 * @param estado ID del filtro de estado
 * @param fecha__desde Filtro de fecha DESDE
 * @param fecha__hasta Filtro de fecha HASTA
*/
    bandejaComite(
        page = 1,
        page_size = 10,
        beneficiario: any = '',
        oficial_negocio: any = '',
        responsable: any = '',
        estado: any = '',
        estacion: any = '',
        fecha__desde: any = '',
        fecha__hasta: any = '',
        comite: any = '',
        analista_riesgo: any = '',
        oficial_negocio__nombre: any = '',
        monto__range: any = '',
    ) {
        const url = this.apiUrl +
            this.operacionesComite +
            `?beneficiario=${beneficiario}` +
            `&page=${page}` +
            `&page_size=${page_size}` +
            `&oficial_negocio=${oficial_negocio}` +
            `&responsable=${responsable}` +
            `&estado=${estado}` +
            `&estacion=${estacion}` +
            `&fecha__gte=${fecha__desde}` +
            `&fecha__lte=${fecha__hasta}` +
            `&analista_riesgo=${analista_riesgo}` +
            `&monto__range=${monto__range}` +
            `&oficial_negocio__nombre=${oficial_negocio__nombre}` +
            `&comite=${comite}`;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    obtenerOperacionCmite(operacion: number) {
        const url = this.apiUrl + this.operacionesComite + `/${operacion}`;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    this.showErrors(err);
                })
        });
    }

    obtenerTiposDeLineas() {

        const url = this.apiUrl + this.lineas;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })

    }

    /**
     * Obtener las lineas de credito del beneficiario
     * @param beneficiarioId 
     */
    obtenerLineas(
        beneficiarioId,
        tipo = ''
    ) {

        const url = this.apiUrl + this.beneficiarios + 'lineas/' +
            `?beneficiario=${beneficiarioId}` +
            `&tipo=${tipo}`;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })

    }

    actualizarLinea(data, lineaId) {
        const url = this.apiUrl + this.beneficiarios + `lineas/${lineaId}/`

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.put(url, data)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        });
    }

    obtenerCuentas(beneficiarioId) {
        const url = this.apiUrl + this.beneficiarios + 'cuentasabono/' +
            `?beneficiario=${beneficiarioId}`;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        });
    }

    /**
    * @param page Pagina del request
    * @param page_size Resultados por pagina
    */
    obtenerMonedas() {
        const url = this.apiUrl + this.monedas;

        return new Promise((res, ref) => {
            this.spinnerOn();
            this.http.get(url).subscribe(
                (response) => {
                    this.spinnerOff();
                    res(response);
                },
                (err) => {
                    this.spinnerOff();
                    ref(err);
                }
            );
        });
    }

    actualizarPropiedadOperacion(operacion: number, body) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.patch(`${this.apiUrl}${this.operacionResumenEndpoint}${operacion}/`, body)
                .subscribe((response) => {
                    this.spinnerOff('Operación realizada con éxito');
                    res(response);
                }, (err) => {
                    this.spinnerOff('La Operación falló');
                    ref(err);
                })
        })
    }

    async updateResumenProps(id: number, body) {

        const URL = this.apiUrl + 'factoring/operaciones/resumen/' + id + '/';
        this.spinnerOn()
        return new Promise((resolve, reject) => {

            const httpRequest = this.http.patch(URL, body);
            httpRequest.subscribe(data => {
                this.spinnerOff('Operación actualizada')
                resolve(data);
            }, (err) => {
                this.spinnerOff()
                reject(err);
            });

        });

    }

    movimientosOperacion(operacionId: number, page: number = 1, page_size: number = 10) {
        const URL = this.apiUrl + this.movimientos + `${operacionId}/` +
            `?page=${page}` +
            `&page_size=${page_size}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

    validaUbigeoDetalle(operacionId: number) {
        const URL = this.apiUrl + `factoring/operaciones/${operacionId}/validar_ubigeos_aceptantes/`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

}
