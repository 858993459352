import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FactoringDatatableComponent } from './factoring-datatable/factoring-datatable.component';
import { PaginationComponent } from './pagination/pagination.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastComponent } from './toast/toast.component';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { MonedaPipe } from '../core/pipes/moneda.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConfirmModalComponent } from './utils/confirm-modal/confirm-modal.component';
import { CajaComentariosComponent } from './caja-comentarios/caja-comentarios.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { MiniAlertComponent } from './mini-alert/mini-alert.component';
import { SessionNotificationComponent } from './session-notification/session-notification.component';
import { TemToTeaPipe } from './pipes/tem-to-tea.pipe';
import { OpenTextModalComponent } from './modals/open-text-modal/open-text-modal.component';
import { StringDateHourPipe } from 'app/core/pipes/string-date-hour.pipe';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
    declarations: [
        FactoringDatatableComponent, 
        PaginationComponent, 
        ToastComponent,
        MonedaPipe,
        StringDateHourPipe,
        ConfirmModalComponent,
        CajaComentariosComponent,
        UploadFilesComponent,
        MiniAlertComponent,
        SessionNotificationComponent,
        TemToTeaPipe,
        OpenTextModalComponent
        ],
        imports: [
            CommonModule,
            NgxSkeletonLoaderModule,
            JwBootstrapSwitchNg2Module,
            UiSwitchModule.forRoot({
                size              : 'medium',
                color             : '#3e50b4',
                checkedTextColor  : "#ffffff",
                uncheckedTextColor: "#ffffff",
                defaultBgColor    : '#6c757d',
                defaultBoColor    : '#3e50b4',
                checkedLabel      : 'Si ',
                uncheckedLabel    : 'No'
            }),
            NgbModule,
            FormsModule,
            ReactiveFormsModule,
            NgSelectModule,
            BrowserAnimationsModule,
            BsDatepickerModule.forRoot(),
            NgxMaskModule.forRoot(maskConfig),
            NgxCurrencyModule,
            TooltipModule,
            BsDropdownModule.forRoot(),
            FontAwesomeModule
        ],
        entryComponents: [
            ConfirmModalComponent
        ],
        exports: [
            FactoringDatatableComponent, 
            PaginationComponent,
            ToastComponent,
            JwBootstrapSwitchNg2Module,
            NgbModule,
            NgSelectModule,
            StringDateHourPipe,
            MonedaPipe,
            CajaComentariosComponent,
            UploadFilesComponent,
            SessionNotificationComponent,
            TemToTeaPipe
        ],
        schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
    })
    export class SharedModule { }
    
