import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-session-notification",
    templateUrl: "./session-notification.component.html",
    styleUrls: ["./session-notification.component.css"],
})

export class SessionNotificationComponent implements OnInit {
    @ViewChild("modalAlerta", { static: false }) 
	public modalAlerta;
    public alertType: string;
    public alertTitle: string;
    public alertBody: string;
    public biSelect: boolean = true;
    @Output() response = new EventEmitter<any>();
    @Input() counter = 0;
    faExclamation = faExclamation;

    constructor(public modalService: NgbModal) {}

    ngOnInit(): void {}

    displayModal() {
        this.cerrarTodos();
        this.modalService.open(this.modalAlerta, {
			size: "md",
			backdrop: 'static'
		}).result.then((response: any) => {
			if (response) {
				this.response.emit({ response: true, type: this.alertType });
			} else {
				this.response.emit({ response: false, type: this.alertType });
			}
		}).catch((err) => {
			this.response.emit({ response: false, type: this.alertType });
		});
    }

    alertaTokenVencido() {
        this.alertType = "token_vencido";
        this.alertTitle = "Token Vencido";
        this.alertBody = "El token de su session ha caducado, desea refrescarlo?";
        this.biSelect = true;
        this.displayModal();
    }

    alertaInactividad() {
        this.alertType = "alerta_inactividad";
        this.alertTitle = "Alerta de Inactividad";
        this.alertBody = "Hemos detectado muy poca actividad en el navegador. La sesión se cerrará en los próximos segundos.";
        this.biSelect = true;
        this.displayModal();
    }

    cerrarTodos() {
        this.modalService.dismissAll();
    }
}
