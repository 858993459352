import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin, Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { ToastService } from "../toast/toast.service";

@Injectable({
  providedIn: "root",
})
export class ClientesService {
  private apiUrl                  : string = "";
  public sectores                 : string = "clientes/sectores/";
  public departamentos            : string = "clientes/ubigeos/departamentos/";
  public provincias               : string = "clientes/ubigeos/provincias/";
  public distritos                : string = "clientes/ubigeos/distritos/";
  public paises                   : string = "clientes/ubigeos/paises/";

  public contactos                : string = "clientes/clientes/contactos/";
  public accionistas              : string = "clientes/clientes/accionistas/";
  public personas                 : string = "clientes/personas/";
  public directores               : string = "clientes/clientes/directores/";
  public vinculos                 : string = "clientes/clientes/entidadesvinculos/";
  public ejecutivos               : string = "clientes/clientes/ejecutivos/";
  public sunat                    : string = "clientes/sunat/";
  public sunat2                   : string = "clientes/personas/consulta/ruc/";
  public registros                : string = "clientes/registros/";
  public clasificaciones          : string = "clientes/clasificaciones/";
  public clientes                 : string = "clientes/clientes/";
  public cuentasAbono             : string = "factoring/beneficiarios/cuentasabono/";
  public relaciones               : string = "clientes/relaciones/";
  public ciiu                     : string = "clientes/clientes/ciiu/";
  public firmantes                : string = "factoring/contratos/firmantes/"
  public operaciones                : string = "factoring/operaciones/"

  public locales                  : string = "clientes/locales/";
  public localesTipos             : string = "clientes/locales/tipos/";
  public localesTiposPropiedad    : string = "clientes/locales/tipospropiedad/";
  public activos                  : string = "clientes/activos/";
  public activosTipos             : string = "clientes/activos/tipos/";
  public ingresos                 : string = "clientes/clientes/descripcioningresos/";

  public monedas                  : string = "clientes/monedas/";
  public creditosTipos            : string = "clientes/creditos/tipos/";
  public entidadesFinancieras     : string = "clientes/entidadesfinancieras/";

  public creditosComerciales      : string = "clientes/creditos/comerciales/";
  public creditosOperaciones      : string = "clientes/creditos/operaciones/";
  public otrosCreditos            : string = "clientes/creditos/";

  public tiposDocumentos          : string = "clientes/personas/tiposdocumentos/";
  public tiposEndeudamientos      : string = "clientes/clasificaciones/tiposcondicionendeudamiento/";

  public oficiales                : string = "factoring/responsables/";
  public gruposEconomicos         : string = "clientes/gruposeconomicos/";
  public lineasHistoricos         : string = "factoring/beneficiarios/lineas/historico/";
  public beneficiarios            : string = "factoring/beneficiarios/";
  public tipoInscrpcion           : string = "clientes/personas/tipoinscripcion";
  public tipoConservador          : string = "clientes/personas/tipoconservador";
  public avalesTipos              : string = "clientes/avales/tipos";
  public avales                   : string = "clientes/avales/";
  public regimenesPatrimoniales   : string = "clientes/personas/regimenesmatrimoniales";
  public pagares                  : string = "clientes/pagares/";
  public pagaresTiposDocumentos   : string = "clientes/pagares/tiposdocumento/";
  public tiposPagares             : string = "clientes/pagares/tipospagare/";
  public custodias                : string = "clientes/pagares/custodias/";
  public tiposEmision             : string = "clientes/pagares/tiposemision/";
  public tiposEmisor              : string = "clientes/pagares/tiposemisor/";

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
  }

  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();

    this.appService.notifyMe(mensaje,ok)
    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);

  }

  obtenerTiposAvales(page: number = 1, page_size: number = 10) {
    const url =
      this.apiUrl + this.avalesTipos + `?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  } 
  
  obtenerAvales(cliente, page: number = 1, page_size: number = 10) {
    const url =
      this.apiUrl + this.avales + `?page_size=${page_size}&page=${page}&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  } 

  guardarAval(body) {
    const url =
      this.apiUrl + this.avales;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url,body).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  } 

  actualizarAval(id,body) {
    const url =
      this.apiUrl + this.avales + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.patch(url,body).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  } 

  obtenerRegimenesPatrimoniales(page: number = 1, page_size: number = 10) {
    const url =
      this.apiUrl + this.regimenesPatrimoniales + `?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }   

  guardarRegimenPatrimonial(body) {
    const url =
      this.apiUrl + this.regimenesPatrimoniales;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url,body).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerSector(page: number = 1, page_size: number = 10) {
    const url =
      this.apiUrl + this.sectores + `?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna los clientes
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   * @param oficial_negocio__icontains
   * @param nombre__icontains
   * @param sector
   * @param ruc__icontains
   */
  obtenerClientes(
    page: number = 1,
    page_size: number = 10,
    ruc__icontains: string = "",
    nombre__icontains: string = "",
    sector: string = "",
    oficial_negocio__icontains: String = ""
  ) {
    const url =
      this.apiUrl +
      this.clientes +
      `?oficial_negocio_nombre__icontains=${oficial_negocio__icontains}` +
      `&nombre__icontains=${nombre__icontains}` +
      `&sector=${sector}` +
      `&ruc__icontains=${ruc__icontains}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna el cliente por un id consultado
   * @param id ID del cliente a consultar
   */
  obtenerCliente(id) {
    const url = this.apiUrl + this.clientes + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna la informacion del cliente por un RUC dado.
   * @param ruc RUC del cliente
   */
  buscarRuc(ruc) {
    const url = this.apiUrl + this.sunat2 + `${ruc}/`;
    this.spinnerOn();
    return new Promise((res, ref) => {
      
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("Falló la consulta a SUNAT", false);
          ref(err);
        }
      );
    });
  }


  buscarCiiu(codigo: any = '', page: number = 1, page_size: number = 10) {
    const URL = this.apiUrl +
      this.ciiu +
      `?search=${codigo}` +
      `&page=${page}` +
      `&page_size=${page_size}`;

    return new Promise((resolve, reject) => {
      const httpRequest = this.http.get(URL);
      httpRequest.subscribe(data => {
        resolve(data);
      }, (err) => {
        reject(err);
      });

    });
  }


  buscarCiiuObserver(codigo: any = '', page: number = 1, page_size: number = 10): Observable<any> {
    const URL = this.apiUrl +
      this.ciiu +
      `?search=${codigo}` +
      `&page=${page}` +
      `&page_size=${page_size}`;
    return this.http
      .get<any>(URL)
      .pipe(map(resp => {
        if (resp.Error) {
          throwError(resp.Error);
        } else {
          return resp.results;
        }
      })
      );
  }

  corroborarDatosSunat(clienteId) {
    const url = this.apiUrl + this.sunat + `corroborar/${clienteId}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("Falla la consulta al SUNAT.", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Guarda o edita un cliente
   * @param data Datos a registrar
   * @param clienteId ID del cliente si se trata de edicion.
   */
  guardarCliente(data, clienteId = 0) {
    const url = clienteId
      ? this.apiUrl + this.clientes + `${clienteId}/`
      : this.apiUrl + this.clientes;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (clienteId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fueregistrado exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  /**
   * Obtiene los paises
   * @param page
   * @param page_size
   */
  obtenerPaises(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl + this.paises + `?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  /**
   * Obtiene los departamentos
   * @param page
   * @param page_size
   */
  obtenerDepartamentos(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.departamentos +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  /**
   * Obtiene las provincias para un departamente
   * @param departamento ID del departamento
   * @param page
   * @param page_size
   */
  obtenerProvincias(departamento, page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.provincias +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&departamento=${departamento}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  /**
   * Obtiene los distritos para un provincia
   * @param provincia ID de la provincia
   * @param page
   * @param page_size
   */
  obtenerDistritos(provincia, page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.distritos +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&provincia=${provincia}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  obtenerTiposDocumentos(page: number = 1, page_size: number = 1000) {
    const url = this.apiUrl + this.tiposDocumentos +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  obtenerTiposEndeudamientos(page: number = 1, page_size: number = 1000) {
    const url = this.apiUrl + this.tiposEndeudamientos +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  obtenerTipoInscripcion(page: number = 1, page_size: number = 1000) {
    const url = this.apiUrl + this.tipoInscrpcion +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  obtenerTipoConservador(page: number = 1, page_size: number = 1000) {
    const url = this.apiUrl + this.tipoConservador +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  obtenerGriposEconomicos(page: number = 1, page_size: number = 1000) {

    const url = this.apiUrl + this.gruposEconomicos +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
    
  }

  /**
   *
   * @param cliente
   * @param page
   * @param page_size
   */
  obtenerContactos(
    cliente: string = "",
    page: number = 1,
    page_size: number = 10
  ) {
    const url =
      this.apiUrl +
      this.contactos +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   *
   * @param cliente
   * @param page
   * @param page_size
   */
  obtenerRepresentanteLegal(
    cliente: string = "",
    page: number = 1,
    page_size: number = 10
  ) {
    const url =
      this.apiUrl +
      this.contactos +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}` +
      `&representante_legal=true`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarContacto(data, contactoId = 0) {
    const url = contactoId
      ? this.apiUrl + this.contactos + `${contactoId}/`
      : this.apiUrl + this.contactos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (contactoId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  guardarGrupoContacto(data) {
    const url = this.apiUrl + this.contactos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      let arrayOfData = [];
      data.forEach(element => {
          arrayOfData.push(this.http.post(url,element));
      });

      forkJoin(arrayOfData).subscribe(response => {
        this.spinnerOff('El archivo fue adjuntado de manera exitosa');
        res(response);
      }, err => {
          const errorFormat = 'Ocurrió un error, el formato del documento no es el adecuado';
          const error = (err.error['linea disponible']) ? err.error['linea disponible'] : errorFormat;
          this.spinnerOff(error,false);
          ref(err);
      });

      // data.forEach((element) => {
      //   this.http.post(url, element).subscribe(
      //     (response) => {
      //       // this.spinnerOff();
      //       res(response);
      //     },
      //     (err) => {
      //       this.spinnerOff("La operación falló", false);
      //       ref(err);
      //     }
      //   );
      // });

      // this.spinnerOff();
    });
  }

  /**
   *
   * @param contactId
   */
  obtenerContacto(contactId) {
    const url = this.apiUrl + this.contactos + `${contactId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  elimiarContacto(contactId) {
    const url = this.apiUrl + this.contactos + `${contactId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Contacto Eliminado!");
          res(response);
        },
        (err) => {
          if (err.status == 403) {
            this.spinnerOff(err.error.detail, false);
          }

          else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  /**
   *
   * @param contactId
   * @param pageSize
   */
   obtenerFirmante(contactId, pageSize) {
    const url = this.apiUrl + this.firmantes + `?firmante=${contactId}&page_size=${pageSize}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }


  /**
   *
   * @param contractId
   * @param pageSize
   */
   obtenerOperaciones(contractId, pageSize) {
    const url = this.apiUrl + this.operaciones + `?contrato=${contractId}&page_size=${pageSize}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   *
   * @param cliente
   * @param page
   * @param page_size
   */
  obtenerAccionistas(
    cliente: string = "",
    page: number = 1,
    page_size: number = 10
  ) {
    const url =
      this.apiUrl +
      this.accionistas +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerAccionista(shareholderId) {
    const url = this.apiUrl + this.accionistas + `${shareholderId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarAccionista(data, accionistaId = 0) {
    const url = accionistaId
      ? this.apiUrl + this.accionistas + `${accionistaId}/`
      : this.apiUrl + this.accionistas;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (accionistaId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarAccionista(accionistaId) {
    const url = this.apiUrl + this.accionistas + `${accionistaId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Accionista Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  tiposPersonas(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.personas +
      "tipos/" +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  estadosCiviles(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.personas +
      "estadosciviles/" +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  oficinas(zona = '', page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.registros +
      "oficinas/" +
      `?zona=${zona}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  zonas(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.registros +
      "zonas/" +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  tiposCreditos(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.clasificaciones +
      "tiposcredito/" +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  magnitudes(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.clasificaciones +
      "magnitudes/" +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  riesgosCambiarios(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.clasificaciones +
      "riesgoscambiario/" +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  obtenerClasificaciones(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.clasificaciones +
      "calificaciones/" +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  obtenerDirectores(
    cliente: string = "",
    page: number = 1,
    page_size: number = 10
  ) {
    const url =
      this.apiUrl +
      this.directores +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerDirector(directorId) {
    const url = this.apiUrl + this.directores + `${directorId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarDirector(data, directorId = 0) {
    const url = directorId
      ? this.apiUrl + this.directores + `${directorId}/`
      : this.apiUrl + this.directores;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (directorId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Director Editado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Director Creado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarDirector(directorId) {
    const url = this.apiUrl + this.directores + `${directorId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Director Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Entidades Vinculos
   */

  obtenerVinculos(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.vinculos +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerVinculo(vinculoId) {
    const url = this.apiUrl + this.vinculos + `${vinculoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarVinculo(data, vinculoId = 0) {
    const url = vinculoId
      ? this.apiUrl + this.vinculos + `${vinculoId}/`
      : this.apiUrl + this.vinculos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (vinculoId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Director Editado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Director Creado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarVinculo(vinculoId) {
    const url = this.apiUrl + this.vinculos + `${vinculoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Director Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Entidades Vinculos
   */

  /**
   * Administracion
   */
  obtenerEjecutivos(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.ejecutivos +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerEjecutivo(ejecutivoId) {
    const url = this.apiUrl + this.ejecutivos + `${ejecutivoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarEjecutivo(data, ejecutivoId = 0) {
    const url = ejecutivoId
      ? this.apiUrl + this.ejecutivos + `${ejecutivoId}/`
      : this.apiUrl + this.ejecutivos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (ejecutivoId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Director Editado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Director Creado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarEjecutivo(ejecutivoId) {
    const url = this.apiUrl + this.ejecutivos + `${ejecutivoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Director Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }
  /**
   * Fin Administracion
   */

  /**
   * Informacion de Negocio
   */

  obtenerActividades(sector = "", page: number = 1, page_size: number = 10) {
    const url =
      this.apiUrl +
      this.sectores +
      "actividades" +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&sector=${sector}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Informacion de Negocio
   */

  /**
   * Locales Cliente
   */

  obtenerLocalesTipos() {
    const url = this.apiUrl + this.localesTipos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerLocalesTiposPropiedad() {
    const url = this.apiUrl + this.localesTiposPropiedad;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerLocales(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.locales +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerLocal(LocalId) {
    const url = this.apiUrl + this.locales + `${LocalId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarLocal(data, LocalId = 0) {
    const url = LocalId
      ? this.apiUrl + this.locales + `${LocalId}/`
      : this.apiUrl + this.locales;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (LocalId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Local Editado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Local Creado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarLocal(LocalId) {
    const url = this.apiUrl + this.locales + `${LocalId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Local Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Locales Cliente
   */

  /**
   * Ingresos
   */

  obtenerIngresos(clienteId) {
    const url = this.apiUrl + this.ingresos + `${clienteId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
          if (!(Number(err?.status) === 404)) {
            this.spinnerOff("La operación falló", false);
          } else {
            this.spinnerOff();
          }
        }
      );
    });
  }

  guardarIngresos(data, clienteId = 0, exist) {
    const url =
      this.apiUrl + this.ingresos + `${exist ? String(clienteId) + "/" : ""}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http[exist ? "patch" : "post"](url, data).subscribe(
        (response) => {
          this.spinnerOff("Ingresos guardados exitosamente!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Ingresos
   */

  /**
   * Cartera Proveedores
   */

  obtenerRelacionesProveedores(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.relaciones +
      "proveedores/" +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerRelacionProveedor(RelacionProveedorId) {
    const url =
      this.apiUrl +
      this.relaciones +
      "proveedores/" +
      `${RelacionProveedorId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarRelacionProveedor(data, RelacionProveedorId = 0) {
    const url = RelacionProveedorId
      ? this.apiUrl +
        this.relaciones +
        "proveedores/" +
        `${RelacionProveedorId}/`
      : this.apiUrl + this.relaciones + "proveedores/";

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (RelacionProveedorId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Proveedor Editado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Proveedor Creado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarRelacionProveedor(RelacionProveedorId) {
    const url =
      this.apiUrl +
      this.relaciones +
      "proveedores/" +
      `${RelacionProveedorId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Proveedor Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Cartera Proveedores
   */

  /**
   * Cartera Clientes
   */

  obtenerRelacionesClientes(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.relaciones +
      "clientes/" +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerRelacionCliente(RelacionClienteId) {
    const url =
      this.apiUrl + this.relaciones + "clientes/" + `${RelacionClienteId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarRelacionCliente(data, RelacionClienteId = 0) {
    const url = RelacionClienteId
      ? this.apiUrl + this.relaciones + "clientes/" + `${RelacionClienteId}/`
      : this.apiUrl + this.relaciones + "clientes/";

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (RelacionClienteId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Cliente Editado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Cliente Creado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarRelacionCliente(RelacionClienteId) {
    const url =
      this.apiUrl + this.relaciones + "clientes/" + `${RelacionClienteId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Cliente Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Cartera Clientes
   */

  /**
   * Cartera Competidores
   */

  obtenerRelacionesCompetidores(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.relaciones +
      "competidores/" +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerRelacionCompetidor(RelacionCompetidorId) {
    const url =
      this.apiUrl +
      this.relaciones +
      "competidores/" +
      `${RelacionCompetidorId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarRelacionCompetidor(data, RelacionCompetidorId = 0) {
    const url = RelacionCompetidorId
      ? this.apiUrl +
        this.relaciones +
        "competidores/" +
        `${RelacionCompetidorId}/`
      : this.apiUrl + this.relaciones + "competidores/";

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (RelacionCompetidorId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Competidor Editado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Competidor Creado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarRelacionCompetidor(RelacionCompetidorId) {
    const url =
      this.apiUrl +
      this.relaciones +
      "competidores/" +
      `${RelacionCompetidorId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Competidor Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Cartera Competidores
   */

  /**
   * Cartera Activos
   */

  obtenerActivosTipos() {
    const url = this.apiUrl + this.activosTipos;
    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerActivos(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.activos +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerActivo(ActivoId) {
    const url = this.apiUrl + this.activos + `${ActivoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarActivo(data, ActivoId = 0) {
    const url = ActivoId
      ? this.apiUrl + this.activos + `${ActivoId}/`
      : this.apiUrl + this.activos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (ActivoId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Activo Editado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Activo Creado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarActivo(ActivoId) {
    const url = this.apiUrl + this.activos + `${ActivoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Activo Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Cartera Activos
   */

  /**
   * Créditos Comerciales
   */

  obtenerCreditosComerciales(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.creditosComerciales +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerCreditoComercial(CreditoComercialId) {
    const url =
      this.apiUrl + this.creditosComerciales + `${CreditoComercialId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarCreditoComercial(data, CreditoComercialId = 0) {
    const url = CreditoComercialId
      ? this.apiUrl + this.creditosComerciales + `${CreditoComercialId}/`
      : this.apiUrl + this.creditosComerciales;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (CreditoComercialId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Crédito Comercial Editado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Crédito Comercial Creado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarCreditoComercial(CreditoComercialId) {
    const url =
      this.apiUrl + this.creditosComerciales + `${CreditoComercialId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Crédito Comercial Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Créditos Comerciales
   */

  /**
   * Créditos Operaciones
   */

  obtenerCreditosOperaciones(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.creditosOperaciones +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerCreditoOperaciones(CreditoOperacionesId) {
    const url =
      this.apiUrl + this.creditosOperaciones + `${CreditoOperacionesId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarCreditoOperaciones(data, CreditoOperacionesId = 0) {
    const url = CreditoOperacionesId
      ? this.apiUrl + this.creditosOperaciones + `${CreditoOperacionesId}/`
      : this.apiUrl + this.creditosOperaciones;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (CreditoOperacionesId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Crédito Operaciones Editado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Crédito Operaciones Creado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarCreditoOperaciones(CreditoOperacionesId) {
    const url =
      this.apiUrl + this.creditosOperaciones + `${CreditoOperacionesId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Crédito Operaciones Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Créditos Operaciones
   */

  /**
   * Otros Créditos
   */

  obtenerOtrosCreditos(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.otrosCreditos +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerOtroCredito(OtroCreditoId) {
    const url = this.apiUrl + this.otrosCreditos + `${OtroCreditoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarOtroCredito(data, OtroCreditoId = 0) {
    const url = OtroCreditoId
      ? this.apiUrl + this.otrosCreditos + `${OtroCreditoId}/`
      : this.apiUrl + this.otrosCreditos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (OtroCreditoId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Otro Crédito Editado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Otro Crédito Creado!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarOtroCredito(OtroCreditoId) {
    const url = this.apiUrl + this.otrosCreditos + `${OtroCreditoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Otro Crédito Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Otros Créditos
   */

  /**
   * Relaciones Financieras
   */

  obtenerTiposCreditos() {
    const url = this.apiUrl + this.creditosTipos;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerEntidadesFinancieras() {
    const url = this.apiUrl + this.entidadesFinancieras;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerListaMonedas() {
    const url = this.apiUrl + this.monedas;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerRelacionesFinancieras(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.relaciones +
      "financieras/" +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerRelacionFinanciera(RelacionFinancieraId) {
    const url =
      this.apiUrl +
      this.relaciones +
      "financieras/" +
      `${RelacionFinancieraId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarRelacionFinanciera(data, RelacionFinancieraId = 0) {
    const url = RelacionFinancieraId
      ? this.apiUrl +
        this.relaciones +
        "financieras/" +
        `${RelacionFinancieraId}/`
      : this.apiUrl + this.relaciones + "financieras/";

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (RelacionFinancieraId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Relación Financiera Editada!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Relación Financiera Creada!");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarRelacionFinanciera(RelacionFinancieraId) {
    const url =
      this.apiUrl +
      this.relaciones +
      "financieras/" +
      `${RelacionFinancieraId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Relación Financiera Eliminada!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Relaciones Financieras
   */

  /**
   * Cuentas Abono Cliente
   */

  obtenerCuentasAbonos(clienteId) {
    const url = this.apiUrl + this.cuentasAbono + `?beneficiario=${clienteId}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerCuentasAbono(clienteId) {
    const url = this.apiUrl + this.cuentasAbono + `/${clienteId}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarCuentaAbono(data, cuentaId = 0) {
    const url = cuentaId
      ? this.apiUrl + this.cuentasAbono + `${cuentaId}/`
      : this.apiUrl + this.cuentasAbono;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (cuentaId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Director Editado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Director Creado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarCuentaAbono(cuentaId) {
    const url = this.apiUrl + this.cuentasAbono + `${cuentaId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Director Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerOficiales(rol = '', estado = '') {
    const url = this.apiUrl + this.oficiales + `?rol=${rol}` + 
              `&estado=${estado}` +
              `&page_size=${1000}` +
              `&page=${1}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerLineasHstorico(
    beneficiario: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl + this.lineasHistoricos +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&beneficiario=${beneficiario}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  actualizarBeneficiario(beneficiarioId, data) {
    const url = this.apiUrl + this.beneficiarios + `${beneficiarioId}/`;
    
    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.put(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });

  }

  updateParcialCliente(clienteId, data) {
    const url = this.apiUrl + this.clientes + `${clienteId}/`;
    
    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.patch(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });

  }

  /**
   * Documentos Respaldos
   */

  obtenerPagares(
    cliente: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url = this.apiUrl + this.pagares +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cliente=${cliente}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  pagaresData(
    urlEndPoint : string = '',
    page        : number = 1,
    page_size   : number = 1000
  ) {
    const url =
      this.apiUrl + this.pagares + `${urlEndPoint}/` +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerPagare(pagareId) {
    const url = this.apiUrl + this.pagares + `/${pagareId}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarPagare(data, pagareId = 0) {
    const url = pagareId
      ? this.apiUrl + this.pagares + `${pagareId}/`
      : this.apiUrl + this.pagares;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (pagareId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("Pagaré Editado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("Pagaré Creado");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarPagare(pagareId) {
    const url = this.apiUrl + this.pagares + `${pagareId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Pagaré Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  eliminarAval(avalId) {
    const url = this.apiUrl + this.avales + `${avalId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Aval Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Documentos Respaldos
   */

}
