import { Component, OnInit } from '@angular/core';
import { ToastService } from 'app/core/services/toast/toast.service';


@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {

  constructor(public toast : ToastService) { }

  ngOnInit(): void {
  }

}
