import {
  Component,
  OnInit,
  AfterViewInit,
  AfterViewChecked,
  AfterContentInit,
} from "@angular/core";
import { AuthService } from "../core/services/auth/auth.service";

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  collapse?: string;
  icontype: string;
  isComite?: boolean;
  fontAwesomeIcon?: any;
  accessAllowed?: any[];
  children?: any[];
}

// export interface ChildrenItems {
//     ab   : string;
//     path: string;
//     title: string;
//     type: string;
//     collapse?: string;
//     icontype: string;
//     isComite?: boolean;
//     fontAwesomeIcon?: any,
//     accessAllowed?: any[];
//     children?: ChildrenItems[];
// }

@Component({
  moduleId: module.id,
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent {
  // faMoneyBillWave  = faMoneyBillWave;

  public menuItems: any[] = [];

  constructor(public auth: AuthService) { }

  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }

  ngOnInit() {
  }

  ngOnChanges(change) { }
}
