import { Injectable, ViewChild } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { SessionNotificationComponent } from 'app/shared/session-notification/session-notification.component';
import { AppComponent } from 'app/app.component';
import { LocalServiceService } from '../services/local-service.service';
import { AppService } from 'app/app.service';
import { ToastService } from '../services/toast/toast.service';
// import { APP_ID } from '../../configs/app-settings.config';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    @ViewChild(AppComponent) alertModal: AppComponent;
    
    constructor(
        public appService: AppService,
		public toast     : ToastService,
        public localService: LocalServiceService){
        
    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
    {
        let token = this.localService.getJsonValue('token')
        
        if(!token){
            return next.handle(req)
        }

        const modified = req.clone({ 
            headers: req.headers.set('Authorization', `Bearer ${token}`),
        });

        return next.handle(modified).pipe(
            // tap(evt => {
                // console.log(evt)
                // this.spinnerOff()
                // this.spinner.hide()
            // }),
            catchError((err: HttpErrorResponse) => {
                if(err.status === 503){
                    console.log('Entro en error 503',err)
                    return this.handleErrors(err,next,modified)
                } else {
                    return this.handleErrors(err,next)
                }
                
            })
        );
    }

    handleErrors(err,next,req = null){

        if(!!err && !!err.error && !!err.error.detail){
            /**
             * Caso de generacion de reportes cunado el Aceptante no tiene linea
             */
            console.warn(err.error.detail)

        } else if(!!err && !!err.error && !!err.error.code){
            /**
             * Caso cuando el token de autenticacion no es valido,
             */
            if(err.error.code == 'token_not_valid'){
                console.log(`Error request: ${err.error.code}`)
                if(this.alertModal){
                    this.alertModal.receive('RECEPCION');
                }
            }

        }else if(err.status === 503){
            /**
             * Caso de error 503
             */
            console.log('Entro en caso de error 503')
            this.intercept(req,next)
            next.handle(req)
        }
        else {
            /**
             * Errores no captados
             */
			console.log(`Error no captado en interceptor`)
			console.log(err)
        }
        
        return throwError(err);
    }
        
}