import { Injectable, ViewChild } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UsersModel } from "../../../shared/models/users.model";
import { ToastService } from "../toast/toast.service";
import { AppService } from "app/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from "@angular/router";
import { RouteInfo } from "app/sidebar/sidebar.component";
import { Perfil } from "./Perfil";
import { Usuario } from "./Usuario";
import { SessionNotificationComponent } from "app/shared/session-notification/session-notification.component";
import { Subject } from "rxjs";
import { LocalServiceService } from "../local-service.service";
import {
  faMoneyBillWave,
  faUserEdit,
  faCalendarAlt,
  faCog,
  faHandHoldingUsd,
  faClock,
  faTools,
  faCashRegister,
  faUsers,
  faFileMedical,
  faUsersCog,
  faClipboardCheck,
  faUserTie,
  faUserTag,
  faFunnelDollar,
  faFile,
  faFileInvoiceDollar,
  faCreditCard,
  faBullhorn,
  faFolder,
  faGavel,
  faReceipt,
  faUserFriends,
  faFileExcel,
  faCogs,
  faCoins,
  faFolderOpen,
  faMedal,
  faSignature,
  faClipboardList,
  faUniversity,
  faChartBar,
  faSubscript,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons';
import { environment } from "environments/environment";

const perfil = new Perfil();

export const ROUTES: RouteInfo[] = [
  {
    path: "/users",
    title: "Gestión de usuarios",
    type: "link",
    icontype: "nc-icon nc-circle-10",
    fontAwesomeIcon: faUserEdit,
    isComite: false,
    accessAllowed: [
      perfil.perfilAdmin,
      perfil.perfilClienteID,
      perfil.perfilOficialDeNegocioID,
      perfil.perfilGerenciaComercialID,
      perfil.perfilJefeDeAdmisionID,
      perfil.perfilAnalistaRiesgosID,
    ],
  },
  {
    path: "/parametros-contrasena",
    title: "Parámetros Contraseña",
    type: "link",
    icontype: "nc-icon nc-circle-10",
    fontAwesomeIcon: faUserLock,
    isComite: false,
    accessAllowed: [
      22
    ]
  },
];

interface campanaNotificaciones {
  id: number;
  mensaje: string;
  bagde: number;
  route?: any;
  elementos?: any;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private userToken: string;
  private apiUrl: string = "";
  private signIn: string = "token/";
  private verify: string = "token/verify/";
  private refresh: string = "token/refresh/";
  private apiSession: string = "session/";
  private users: string = "usuarios/";
  private perfiles: string = "perfiles/";
  private sistemas: string = "sistemas/";
  private parametrosContraseña: string = 'password-config/';
  private comiteIntegrante: string = "factoring/comites/integrantes/";
  public userIn: Usuario;
  public operacionSugerenciasBandeja: string = "factoring/sugerencias/bandeja";
  public campanaDeNotificaciones: campanaNotificaciones[] = [];
  public campanaDeOpiniones: any[] = [];
  public perfil: Perfil;
  @ViewChild(SessionNotificationComponent) sessionNotificationComponent: SessionNotificationComponent;
  private displayAlertModal = new Subject<any>();
  private routesAlert = new Subject<any>();
  alertSuscriber$ = this.displayAlertModal.asObservable();
  routesAlert$ = this.routesAlert.asObservable();

  _routes: RouteInfo[];

  public get routes() {
    return ROUTES;
  }

  constructor(
    public appService: AppService,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public router: Router,
    private http: HttpClient,
    public localService: LocalServiceService,
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
    this.readToken();
  }

  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();
    this.appService.notifyMe(mensaje, ok);

    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);
  }

  deleteUsers(user: any) {
    return new Promise((res, rej) => {
      this.http.delete(`${this.apiUrl}${this.users}${user}/`).subscribe(
        (response) => {
          this.toast.success("Se eliminó el usuario correctamente");
          res(response);
        },
        (error) => {
          if (error.status === 401) {
            for (const property in error.error) {
              this.toast.warning(error.error[property]);
              setTimeout(() => {
                this.logout()
              }, 2000);
            }
          } else {
            for (const property in error.error) {
              this.toast.warning(error.error[property]);
            }
          }
          rej(error);
        }
      );
    });
  }

  postUsers(user: any) {
    user.cliente === "" ? delete user.cliente : null;
    return new Promise((res, rej) => {
      this.http.post(`${this.apiUrl}${this.users}`, user).subscribe(
        (response) => {
          this.toast.success("Se creó el usuario correctamente");
          res(response);
        },
        (error) => {
          if (error.status === 401) {
            for (const property in error.error) {
              this.toast.warning(error.error[property]);
              setTimeout(() => {
                this.logout()
              }, 2000);
            }
          } else {
            for (const property in error.error) {
              this.toast.warning(error.error[property]);
            }
          }
          rej(error);
        }
      );
    });
  }



  uploadUsers(user: any, id: any) {
    user.cliente === "" ? delete user.cliente : null;
    user.password === "" ? delete user.password : null;
    // delete user.password
    return new Promise((res, rej) => {
      this.http.put(`${this.apiUrl}${this.users}${id}/`, user).subscribe(
        (response) => {
          this.toast.success("Se actualizó el usuario correctamente");
          res(response);
        },
        (error) => {
          if (error.status === 401) {
            for (const property in error.error) {
              this.toast.warning(error.error[property]);
              setTimeout(() => {
                this.logout()
              }, 2000);
            }
          } else {
            for (const property in error.error) {
              this.toast.warning(error.error[property]);
            }
          }
          rej(error);
        }
      );
    });
  }

  updatePassword(user: any) {
    return new Promise((res, rej) => {
      this.http.put(`${this.apiUrl}${this.users}${user.id}/`, user).subscribe(
        (response) => {
          this.toast.success("Se actualizó el usuario correctamente");
          res(response);
        },
        (err) => {
          this.toast.warning(
            "Ocurrio un error en la actualización del usuario"
          );
          rej(err);
        }
      );
    });
  }

  login(usuario: UsersModel) {
    return (
      new Promise((resolve, rejects) => {
        this.http.post(`${this.apiUrl}${this.signIn}`, usuario).subscribe(
          (response) => {
            console.log(response)
            this.writeToken(response["access"]);
            this.localService.setJsonValue("refresh", response["refresh"]);
            resolve(response);
          },
          (err: any) => {
            if (err.error['contraseña']) {
              this.toast.warning(
                err.error['contraseña'][0]
              );
            }
            rejects(err);
          }
        );
      })
        /**
         * Luego de ejecutar el login
         * returna un token de acceso
         */
        .then((response) => {
          return this.getSession(response["access"]);
        })
        /**
         * Con el token de acceso retornado por el login
         * se obtiene el usuario logueado
         */
        // .then((sessionUser: any) => {
        //   return this.comiteIntegrantes(sessionUser.id);
        // })
        // .then((response: any) => {
        //   return this.getNotifications();
        // })
        .catch((err: any) => {
          throw new Error(JSON.stringify(err));
        })
    );
  }

  comiteIntegrantes(integrante, comite = "", page = 1, page_size = 1000) {
    return new Promise((resolve, reject) => {
      const url =
        this.apiUrl +
        this.comiteIntegrante +
        `?integrante=${integrante}` +
        `&comite=${comite}` +
        `&page=${page}` +
        `&page_size=${page_size}`;

      this.http.get(url).subscribe(
        (response: any) => {
          this.updateUser("comite", response.results);
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * Devuelve los usuarios registrados
   * @param page //Pagina del query
   * @param page_size //Cantidad de registros por pagina
   * @param perfil //ID del perfil del usuario
   * @param estado //ID del estado del usuario
   * @param cliente //ID del cliente/beneficiario del usuario
   * @param fecha_creacion__range //Rango de fechas en las que fue creado
   */
  obtenerUsuarios(
    page: any = 1,
    page_size: any = 10,
    perfil: any = "",
    estado: any = "",
    nombre: any = "",
    cliente: any = "",
    fecha_creacion__range: string = ""
  ) {
    const url =
      this.apiUrl +
      this.users +
      `?cliente=${cliente}` +
      `&page=${page}` +
      `&page_size=${page_size}` +
      `&perfil=${perfil}` +
      `&estado=${estado}` +
      `&nombre=${nombre}` +
      `&fecha_creacion__range=${fecha_creacion__range}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }



  obtenerNombre(
    page: number = 1,
    page_size: number = 10,
    nombre = "",
    apellido = "",
    email = "",
    estado = "",
  ) {
    const url =
      this.apiUrl +
      this.users +
      `?nombre__icontains=${nombre}` +
      `&page=${page}` +
      `&page_size=${page_size}` +
      `&apellido__icontains=${apellido}` +
      `&email__icontains=${email}` +
      `&estado__icontains=${estado}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La operación que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }
  /**
   * Obtiene un usuario especifico
   * @param id ID del usuario
   */
  obtenerUsuario(id) {
    const url = this.apiUrl + this.users + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  /**
 * Obtiene lista con la descripción de los documentos asociados al usuario. 
 */
  obtenerDocumentosDescripcion() {
    const url = this.apiUrl + this.users + `documentos/descripcion/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  uploadDocUsers(doc: any, type: any, id: any, id_doc: any) {
    const formdata = new FormData();
    formdata.append('adjunto', doc);
    formdata.append('usuario', id);
    formdata.append('descripcion', type);
    let url: any;
    if (id_doc !== undefined) {
      url = this.http.put(`${this.apiUrl}${this.users}documentos/${id_doc}/`, formdata)
    } else {
      url = this.http.post(`${this.apiUrl}${this.users}documentos/`, formdata)
    }
    return new Promise((res, rej) => {
      url.subscribe(
        (response) => {
          this.toast.success("Se actualizó el documento correctamente");
          res(response);
        },
        (error) => {
          if (error.status === 401) {
            for (const property in error.error) {
              this.toast.warning(error.error[property]);
              setTimeout(() => {
                this.logout()
              }, 2000);
            }
          } else {
            for (const property in error.error) {
              this.toast.warning(error.error[property]);
            }
          }
          rej(error);
        }
      );
    });
  }

  getFile(url: any) {
    let filename = url.split('/').reverse()[1];
    fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.userToken}`
      }
    }).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      }
      );
    });
  }

  /**
   * Devuelve la lista de clientes registrados
   * @param page Indica la pagina que se desea obtener
   * @param page_size Indica la cantidad de resultados por query
   */
  obtenerPerfiles(sistema: number = null, page: number = 1, page_size: number = 100) {
    let url =
      this.apiUrl + this.perfiles + `?page_size=${page_size}` + `&page=${page}`;

    if (sistema) {
      url += `?sistema=${sistema}`;
    }

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  /**
   * Devuelve la lista de clientes registrados
   * @param page Indica la pagina que se desea obtener
   * @param page_size Indica la cantidad de resultados por query
   */
  obtenerSistemas(page: number = 1, page_size: number = 100) {
    const url =
      this.apiUrl + this.sistemas + `?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  getSession(token) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.apiUrl}${this.apiSession}`, {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token}`),
        })
        .subscribe(
          (res) => {
            this.user = res["usuario"];
            resolve(res["usuario"]);
          },
          (err) => {
            if (err.error.code == "token_not_valid") {
              this.displayAlert();
            }

            reject(err);
          }
        );
    });
  }

  private writeToken(idToken: string) {
    this.userToken = idToken;
    this.localService.setJsonValue("token", idToken);
  }

  private readToken() {
    if (this.localService.getJsonValue("token")) {
      this.userToken = this.localService.getJsonValue("token");
      this.user = this.localService.getJsonValue("user");
      this.getSession(this.userToken)
        .then((sessionUser: any) => {
          // return this.comiteIntegrantes(sessionUser.id);
        })
      // .then((response: any) => {
      //   return this.getNotifications();
      // });
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  public set user(user) {
    this.localService.setJsonValue("user", user);
    this.userIn = new Usuario(user);
    this.perfil = new Perfil(this.userIn);
  }

  public get user() {
    return this.userIn;
  }

  updateUser(prop: string, value: any) {
    return new Promise<void>((resolve, reject) => {
      if (this.localService.getJsonValue("user")) {
        let user = this.localService.getJsonValue("user");
        user[prop] = value;
        this.userIn[prop] = value;
        this.localService.setJsonValue("user", user);
        this._routes = this.filterPerfil();
        resolve();
      } else {
        reject();
      }
    });
  }

  logout() {
    this.localService.clearToken();
    this.router.navigate(["/login"]);
  }

  getNotifications() {
    let sugerencias = this.bandejaDeSugerencias(this.user.id);

    Promise.all([sugerencias]).then((res: any[]) => {
      let sugerencias = res[0];
      let campana: campanaNotificaciones = {
        id: 1,
        mensaje: `Usted tiene ${sugerencias.count} operaciones que requieren su atención`,
        bagde: sugerencias.count,
        elementos: sugerencias.results,
      };

      this.campanaDeOpiniones = sugerencias.results;
      this.actualizarNotificacion(campana);
    });
  }

  bandejaDeSugerencias(idUser, page_size = 1000) {
    const URL =
      this.apiUrl +
      this.operacionSugerenciasBandeja +
      `?responsable=${idUser}` +
      `&page_size=${page_size}`;

    this.spinnerOn();
    return new Promise((res, ref) => {
      this.http.get(URL).subscribe(
        (response: any) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  get notifications() {
    return this.campanaDeNotificaciones;
  }

  get solicitudesDeOpinon() {
    return this.campanaDeOpiniones;
  }

  actualizarNotificacion(item) {
    let index = this.campanaDeNotificaciones.findIndex((i) => i.id === item.id);
    if (index >= 0) {
      this.campanaDeNotificaciones.splice(index, 1, item);
    } else {
      this.campanaDeNotificaciones.push(item);
    }
  }

  filterPerfil(rutas: any[] = ROUTES, perfil = this.user.perfil) {
    if (this.user.comite) {
      rutas.forEach((ruta) => {
        if (this.user.comite.length > 0 && ruta.isComite) {
          ruta.accessAllowed.push(this.user.perfil);
        }
      });
    }

    let rutasFiltradas = rutas.reduce((acc, item) => {
      let bool_value: boolean = false;
      // for (let index = 0; index < perfil.length; index++) {
      //   const element = perfil[index];
      //   if (item.accessAllowed.includes(element)) {
      //     bool_value = true
      //   }
      // }
      if (bool_value || this.user.isAdmin) {
        if (item.children) {
          let childs = this.filterPerfil(item.children);
          item.children = childs;
        }
        acc.push(item);
      }
      return acc;
    }, []);

    // this._routes = rutasFiltradas;
    return rutasFiltradas
  }

  refreshToken() {
    return new Promise((res, rej) => {
      let tokenRefresh = this.localService.getJsonValue("refresh");

      if (!tokenRefresh) return;
      this.http
        .post(`${this.apiUrl}${this.refresh}/`, { refresh: tokenRefresh })
        .subscribe(
          (response: any) => {
            let { access } = response;
            this.writeToken(access);
            location.reload();
            res(response);
          },
          (error) => {
            rej(error);
          }
        );
    });
  }

  displayAlert() {
    this.displayAlertModal.next();
  }

  emitRouteAlert() {
    this.routesAlert.next();
  }

  /**
 * Confiiguracion de contraseña
 */

  obtenerParametrosContrasena(
    page: number = 1,
    page_size: number = 10,
  ) {
    const url = `${this.apiUrl}${this.parametrosContraseña}?page_size=${page_size}&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerParametroContraseña(id) {
    const url = `${this.apiUrl}${this.parametrosContraseña}${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarParametroContraseña(data, parametroId = 0) {
    const url = parametroId
      ? `${this.apiUrl}${this.parametrosContraseña}${parametroId}/`
      : `${this.apiUrl}${this.parametrosContraseña}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (parametroId) {
        this.http.put(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue registrada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  /**
   * Confiiguracion de contraseña
   */
}
