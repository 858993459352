import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringDateHour'
})
export class StringDateHourPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    console.log(value)
    return 'null';
  }

}
