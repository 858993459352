import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {
    @Output() pageEvent  : EventEmitter<number> = new EventEmitter<number>();
    @Input() httpResults : any;
    @Input('initialPage') initialPage: number;
    actualPage  = 1;
    mostrando   = 0;
    total       = 0;
    totalPages;
    pages       = [];
	constructor() {

    }
	
	ngOnInit(): void {
	}

    ngOnChanges(changes) {
        if(changes.httpResults && this.httpResults){
            let count = Number(this.httpResults['count'])
            this.mostrando  = this.httpResults['results'].length
            this.total      = this.httpResults['count']
            this.totalPages = Number(count / 10).toFixed(0);

            if(this.httpResults['count'] % 10 != 0)
                this.totalPages++


            this.pages = []
            for (let index = 0; index < this.totalPages ; index++) {
                this.pages.push(index + 1);
            }            
        }        
    }

    previousPage(){
        if(this.actualPage -1 >  0){
            this.pageEvent.emit(this.actualPage--)
            this.actualPage = this.actualPage--
        }
    }

    nextPage(){
        if(this.actualPage + 1 <= this.totalPages){
            this.pageEvent.emit(this.actualPage++)
            this.actualPage = this.actualPage++
        }        
    }

    goToPage(page: number){
        if(this.actualPage != page){
            this.pageEvent.emit(page)
            this.actualPage = page
        }

    }        
}
