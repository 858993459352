import { Component, OnInit, ElementRef } from '@angular/core';
import { NgForm, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { UsersModel  } from '../../../../shared/models/users.model';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { FormBuilder } from '@angular/forms';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { environment } from 'environments/environment.prod';


declare var $:any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    siteKey:string = environment.recaptchaKey;

	public loginForm: FormGroup;
    user: UsersModel;
    focus;
    focus1;
    focus2;
    test : Date = new Date();
    private toggleButton;
    private sidebarVisible: boolean;
    private nativeElement: Node;
    loginResult = true;
    errorsForm : any = {};
    loginWithErrors = false;
    
    constructor(
        private router   : Router,
        private element  : ElementRef,
        private spinner  : NgxSpinnerService,
        private formbuild: FormBuilder,
        public localService: LocalServiceService,
        private auth     : AuthService) {
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        this.user = new UsersModel();
        this.initForm()
    }
    
    initForm(){
        this.loginForm = this.formbuild.group({
            email  : ['', [Validators.required,Validators.pattern(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/), Validators.minLength(5)]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            recaptcha: ['' , [Validators.required]]
        })
    }

    resolved(captchaResponse: string): void {
        if( captchaResponse != null ){
            this.loginForm.controls.recaptcha.setValue( true );
        }else{
            this.loginForm.controls.recaptcha.setValue( '' );
        }
    }

    submitLogin(){
        if(!this.loginForm.valid) 
        {
            this.loginWithErrors = true
            return
        }
        this.spinner.show();
        this.auth.login(this.loginForm.value)
            .then((res)=>{
                this.spinner.hide();
                if (this.checkRestablecerPassword()) {
                    this.router.navigate(['/change-password']);
                } else {
                    this.router.navigate(['/users']);
                }
            }).catch((err: any)=>{
                this.spinner.hide();
                // if(err.status == 401){
                    this.loginForm.controls.email.setValue( '' );
                    this.loginForm.controls.password.setValue( '' );
                    this.loginResult = false
                // }
            })
    }
    
    checkFullPageBackgroundImage(){
        var $page = $('.full-page');
        var image_src = $page.data('image');
        
        if(image_src !== undefined){
            var image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>'
            $page.append(image_container);
        }
    };
    
    ngOnInit(){
        this.checkFullPageBackgroundImage();
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');
        var navbar : HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        
        setTimeout(function(){
            // after 1000 ms we add the class animated to the login/register card
            $('.card').removeClass('card-hidden');
        }, 700)
    }
    
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');
    }

    checkRestablecerPassword() {
        let user = this.localService.getJsonValue('user');
        console.log(user.restablecer_password);
        return (user.restablecer_password) ? true : false;
    }
    
}



