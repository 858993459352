import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  faPercent,
  faColumns,
  faTimesCircle,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { FactoringService } from "app/core/services/factoring/factoring.service";
import { concat, Observable, of, Subject, throwError } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  map,
  filter,
} from "rxjs/operators";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";

declare var $: any;
export interface columnHeader {
  headerName: string;
  field?: string;
  pipe?: string;
  sortable?: boolean;
  filterable?: boolean;
  class?: string;
  moneda?: string;
  text?: string;
  textField?: string;
  filterSelectItems?: any[];
  filterItemsProps?: any;
  visible?: boolean;
  filterDate?: boolean;
  filterProp?: string;
  filterValue?: any;
  filterInput?: boolean;
  filterRange?: boolean;
  filterValueFrom?: any;
  filterValueTo?: any;
  defaultInfo?: string;
  function?: any;
  postfix?: string;
  filterType?: string;
  filterSearchItems?: any[];
  filterSearchText?: any[];
  actionableType?: string;
  idResponsable?: string;
  responsableField?: string;
  stateField?: string;
  checkDisabled?: boolean;
}

// El datatable recibe los valores de renderizado, pueden ser columnas personalizadas o elementos particulares
// Para elementos particulares adicionales se desarrollan en la seccion de PIPES en el bodu de la tabla
// La tabla retorna los EVENTOS de los botones clickeados

@Component({
  selector: "app-factoring-datatable",
  templateUrl: "./factoring-datatable.component.html",
  styleUrls: ["./factoring-datatable.component.css"],
  animations: [
    trigger("animationOption2", [
      state(
        "close",
        style({
          opacity: 0,
          height: "0px",
        })
      ),
      state(
        "open",
        style({
          height: "60px",
          opacity: 1,
        })
      ),
      transition("close <=> open", animate(250)),
    ]),
  ],
})
export class FactoringDatatableComponent implements OnInit {
  faFilter = faFilter;
  faColumns = faColumns;
  faTimes = faTimesCircle;
  faPercent = faPercent;
  // Ingresan las cabeceres y las filas de la tabla
  columnsToggler: columnHeader[] = [];
  _headers: columnHeader[] = [];
  filtersData: any[];
  @Input() rows: any[] = [];

  @Input() set headers(headers) {
    this.setHeadersFunction(headers);
    this._headers = headers;
  }

  get headers() {
    return this._headers;
  }

  /**
   * Variables de paginacion
   */
  @Input() perPage: number = 10;
  @Input() actualPage: number = 1;
  @Input() countPages: any[] = [];
  @Input() totalRows: number = 0;
  @Input() pagination: boolean = false;

  // Estas variables determinan si se muestran estos botones del crud en la tabla, si todas estan en false, la columna de ACCIONES no se renderiza
  @Input() loading: boolean = false;
  @Input() editAction: boolean = false;
  @Input() deleteAction: boolean = false;
  @Input() readAction: boolean = false;
  @Input() reassignAction: boolean = false;
  @Input() checkAction: boolean = false;
  @Input() toggleColumn: boolean = false;
  @Input() highlighted: Function = () => false;
  @Input() reassignActionFunction: Function = (row) => false;
  reassignRow = {}
  // Estos eventos se emiten cuando uno de los botones del crud es clickeado
  @Output() editEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() readEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleteEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() reassignEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() checkEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() checkChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() workflow: EventEmitter<any> = new EventEmitter<any>();
  @Output() rejectEvent: EventEmitter<any> = new EventEmitter<any>();

  // Define si la primera columna muestra el indice de la fila
  @Input() indexedCol: boolean = false;
  _checkloader: any = {};
  @Input() set checkloader(value) {
    this.updateAsyncLoader(value);
  }

  get checkloader() {
    return this._checkloader;
  }
  @Input() checksEditables: boolean = true;
  @Input() readonly: boolean = false;
  staticFilters: string = '{"beneficiario":""}';
  checkLoading: boolean = false;
  dateValue: any[];
  dateCloseToggle: boolean = false;
  tmp: any = null;
  tmpObj: any = {};
  public rowsBackup: any[] = [];
  @Input() minheight: number = 500;
  observer$: Observable<any[]>;
  observerOficiales$: Observable<any[]>;
  subjectString$ = new Subject<string>();
  subjectStringOficiales$ = new Subject<string>();
  ruc: any;
  loadingSearch: boolean = false;
  loadingSearchOficiales: boolean = false;
  formularioTesting: FormGroup;
  beneficiarioID: any;
  beneficiarioColumnaIndex: any;
  oficialColumnaIndex: any;
  openSelectSearch: boolean = false;
  /**
   * Esta propiedad filtra las filas de la tabla dependiente del string
   * que sea enviado desde el padre
   */
  public _searchstring: string = "true";
  public searchResults = false;
  @Input() set searchstring(text) {
    this.searchStringFunction(text);
    this._searchstring = text;
  }
  get searchstring() {
    return this._searchstring;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private factoringService: FactoringService
  ) {
    this.loadSeach();
  }

  ngOnInit() {
    this.formularioTesting = this._formBuilder.group({
      teststring: [""],
      oficialString: [""],
    });

    this.formularioTesting.controls.teststring.valueChanges.subscribe(
      (value) => {
        this.headers[this.beneficiarioColumnaIndex].filterValue = value;
        this.obtenerFiltrosActivos();
      }
    );

    this.formularioTesting.controls.oficialString.valueChanges.subscribe(
      (value) => {
        this.headers[this.oficialColumnaIndex].filterValue = value;
        this.obtenerFiltrosActivos();
      }
    );
    this.loadSeach();

    if (this.reassignActionFunction === null) {
      this.reassignActionFunction = ()=>true
    }
  }

  closeBeneficiariosSearch() {
    this.formularioTesting.reset();
    this.openSelectSearch = false;
  }

  updateAsyncLoader(value) {
    if (value && value.value && this.rows[value.index]) {
      this.rows[value.index][value.value] = false;
    }
    this._checkloader = value;
  }

  /**
   * Se ejecuta cada vez que una variable dentro del componente cambia o muta
   * @param changes
   */
  ngOnChanges(changes) {
    if (changes.rows && this.rows && this.rows.length > 0) {
      this.rowsBackup = this.rows;
    }

    if (changes) {
      this.rows.forEach(row=>{
        this.reassignRow[row.id] = this.reassignActionFunction(row)
      })
    }

    if (this.checksEditables) this.readCheckboxValues();
  }

  checkboxChange(event, row, field) {
    let index = this.rows.findIndex((item) => item.id == row.id);
    this.rows[index][`checkLoad${field}`] = true;
    this.checkChanged.emit({
      row: row,
      newvalue: event,
      field: field,
      checkEvent: {
        value: `checkLoad${field}`,
        index: index,
      },
    });
  }

  readCheckboxValues() {
    if (
      this.headers &&
      this.headers.length > 0 &&
      this.headers.some((item) => item.pipe == "checkbox")
    ) {
      let checks = this.headers.reduce((acc, item) => {
        if (item.pipe == "checkbox") {
          acc.push(item.field);
        }
        return acc;
      }, []);
      this.mapCheckboxValues(checks);
    }
  }

  mapCheckboxValues(fields) {
    let rows = this.rows.map((item) => {
      fields.forEach((element) => {
        item[`checkLoad${element}`] = false;
      });
      return item;
    });
    this.rows = rows;
  }

  goToPage(page) {
    /**
     * Calcula si existe la pagina que se esta
     * pidiendo a la API, tomando en cuenta la configuracion
     * del numero de resultados por pagina seleccionada
     */
    let paginationInfo = Math.ceil(this.totalRows / this.perPage);
    if (paginationInfo < page) {
      this.goToPage(page - 1);
      return;
    }

    /**
     * Emite evento de actualizacion de pagina
     */
    this.obtenerFiltrosActivos(page);
  }

  perPageUpdate(quantity) {
    this.perPage = quantity;
    this.goToPage(this.actualPage);
  }

  toggleHeader(header) {
    if (!header) {
      this.headers.forEach((h) => (h.visible = true));
    } else {
      let index = this.headers.findIndex((head) => {
        head.field === header.field
      });
      this.headers[index].visible = !this.headers[index].visible;
    }
  }

  /**
   * El evento se dispara cuando se clickea un filtro de una columna
   * @param head Recibe la cabecera del evento donde fue clickeado
   * @param filtered Recibe el item que fue seleccionado del dropdown de opciones
   */
  filterClicked(head, filtered) {
    /**
     * Busca el indice de la columna en la que el filtro fue activado
     */
    let indexColumn = this.headers.findIndex((h) => h.field === head.field);

    if (!filtered) {
      if (head.filterInput) {
        head.filterValue = "";
        this.tmp = null;
        this.tmpObj = {};
      } else if(head.filterRange) {
        head.filterValueFrom = null;
        head.filterValueTo = null;
      }
      else {
        this.headers[indexColumn].filterSelectItems.forEach(
          (f) => (f.selected = false)
        );
      }
      this.obtenerFiltrosActivos();
      return;
    }
    /**
     * Busca el indice del filtro en la columna activada
     */
    let filterIndex = this.headers[indexColumn].filterSelectItems.findIndex(
      (f) => f.id === filtered.id
    );

    /**
     * Cambia el estado de seleccion del filtro
     */
    if (filtered.selected) {
      this.headers[indexColumn].filterSelectItems[filterIndex].selected = false;
    } else {
      /**
       * Limpia todas las otras selecciones, porque se trata de una seleccion simple
       */
      this.headers[indexColumn].filterSelectItems.forEach(
        (f) => (f.selected = false)
      );
      this.headers[indexColumn].filterSelectItems[filterIndex].selected = true;
    }

    this.obtenerFiltrosActivos();
  }

  obtenerFiltrosActivos(page: any = this.actualPage) {
    let filtros = this.headers.reduce((acc, header) => {
      if (
        header.filterable &&
        header.filterType &&
        (header.filterType == "buscarBeneficiario" || header.filterType == "buscarOficial")
      ) {
        // this.openSelectSearch = true
        acc[header.filterProp] = header.filterValue;
      }

      if (header.filterable && header.filterSelectItems) {
        let selected = header.filterSelectItems.find((item) => item.selected);
        if (selected) acc[header.filterProp] = selected.id;
      }

      if (header.filterable && header.filterDate) {
        if (header.filterValue.fecha__gte != "")
          acc["fecha__gte"] = header.filterValue.fecha__gte;

        if (header.filterValue.fecha__lte != "")
          acc["fecha__lte"] = header.filterValue.fecha__lte;
      }

      if (header.filterable && header.filterInput) {
        if (header.filterValue != "") {
          acc[header.filterProp] = header.filterValue;
        }
      }

      if (header.filterable && header.filterRange) {
        if(header.filterValueFrom)
          acc['valueFrom'] = header.filterValueFrom;
        
        if(header.filterValueTo)
          acc['valueTo'] = header.filterValueTo;
      }

      return acc;
    }, {});

    /**
     * Esta sentencia valida si tiene filtros seleccionados nuevos,
     * SI tiene un filtro nuevo seleccionado, pide la pagina 1
     * si NO  tiene un filtro nuevo seleccionado, continua la paginacion natural
     */
    let filtroVacio = JSON.stringify(filtros)==JSON.stringify({}) 
    if(!filtroVacio){
      if (this.staticFilters != JSON.stringify(filtros)) {
        page = 1;
      }
    }

    this.staticFilters = JSON.stringify(filtros);
    let updatePage = {
      page: page,
      per_page: this.perPage,
      filtros: filtros,
    }
    this.pageUpdate.emit(updatePage);
  }
  
  calendarOption(values, head) {
    if (!values || values.length == 0) return;
    let indexColumn = this.headers.findIndex((h) => h.field === head.field);
    this.headers[indexColumn].filterValue.fecha__gte = this.formatDate(
      values[0]
    );
    this.headers[indexColumn].filterValue.fecha__lte = this.formatDate(
      values[1]
    );
    this.dateCloseToggle = true;

    this.obtenerFiltrosActivos();
  }

  formatDate(date) {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " 00:00"
    );
  }

  searchStringFunction(text) {
    if (text.toLowerCase().trim() === "") {
      this.rows = this.rowsBackup;
      this.searchResults = false;
    } else {
      this.rows = this.rowsBackup.reduce((acc, value) => {
        for (var prop in value) {
          if (
            value[prop] &&
            value[prop].toString().toLowerCase().includes(text.toLowerCase())
          ) {
            acc.push(value);
            return acc;
          }
        }
        return acc;
      }, []);
      this.searchResults = true;
    }
  }

  initBuscarBeneficiarios(head, index) {
    this.beneficiarioColumnaIndex = index;
  }

  initBuscarOficial(head, index) {
    this.oficialColumnaIndex = index;
  }

  setHeadersFunction(headers) {
    if (headers && headers.length > 0) {
      this.columnsToggler = headers.map((head: any, index: any) => {
        if (head.filterable && head.filterType) {
          switch (head.filterType) {
            case "buscarBeneficiario":
              head.beneficiarioValue = null;
              head.filterValue = "";
              this.initBuscarBeneficiarios(head, index);
            break;
            case "buscarOficial":
              head.beneficiarioValue = null;
              head.filterValue = "";
              this.initBuscarOficial(head, index);
            break;
          }
        }
        if (head.filterable && head.filterSelectItems) {
          head.filterSelectItems.forEach((filt) => (filt.selected = false));
        }

        if (head.filterable && head.filterDate) {
          head.filterValue = {
            fecha__gte: "",
            fecha__lte: "",
          };
        }

        if (head.filterable && head.filterInput) {
          // head.filterValue = this.tmp ? this.tmp : '';
          head.filterValue = this.tmpObj[head.field]
            ? this.tmpObj[head.field]
            : "";
        }

        head.visible = true;
        return head;
      });
    }
  }

  ejecutaFiltro(header) {
    this.openSelectSearch = true;
  }

  clearDate(head) {
    this.dateValue = [];
    let indexColumn = this.headers.findIndex((h) => h.field === head.field);
    this.headers[indexColumn].filterValue.fecha__gte = "";
    this.headers[indexColumn].filterValue.fecha__lte = "";
    this.dateCloseToggle = false;
    this.obtenerFiltrosActivos();
  }

  computedPercent(val) {
    if (val) return parseFloat(val).toFixed(2);
    else return 0;
  }

  workFlowEvent(row, header) {
    const roww = {
      ...row,
      actionableType: row[header.actionableType],
      actionableName: header.actionableType,
    };

    this.workflow.emit(roww);
  }

  searchString(head) {
    let indexColumn = this.headers.findIndex((h) => h.field === head.field);
    this.tmp = this.headers[indexColumn].filterValue;
    this.tmpObj[this.headers[indexColumn].field] = this.headers[
      indexColumn
    ].filterValue;
    this.obtenerFiltrosActivos();
  }

  /**
   * Este evento se suscribe a los cambios en el objeto que recibe la data desde el servidor
   */
  loadSeach() {
    /**
     * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
     * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
     * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value.
     * 		So that API will not hit if the current and previous value is the same
     * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
     * 		string typed by user
     */
    this.observer$ = concat(
      of([]), // Items predeterminados
      this.subjectString$.pipe(
        filter((res) => {
          return true;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.loadingSearch = true)),
        switchMap((term) => {
          return this.factoringService.buscarBeneficiariosObserver(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.loadingSearch = false))
          );
        })
      )
    );
  }

  loadSeachBusquedaOficiales() {
    /**
     * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
     * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
     * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value.
     * 		So that API will not hit if the current and previous value is the same
     * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
     * 		string typed by user
     */
    this.observerOficiales$ = concat(
      of([]), // Items predeterminados
      this.subjectStringOficiales$.pipe(
        filter((res) => {
          return true;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.loadingSearchOficiales   = true)),
        switchMap((term) => {
          return this.factoringService.buscarBeneficiariosObserver(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.loadingSearchOficiales   = false))
          );
        })
      )
    );
  }

  trackByFn(item: any) {
    return item.id;
  }

  rejectEmit(row) {
    this.rejectEvent.emit(row);
  }

  changePage(item){
    console.log(item)
  }

  cerraBusquedaOficial(){

  }


}
