import { Component, ElementRef, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/services/auth/auth.service';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { UsersModel } from 'app/shared/models/users.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { PasswordMath } from '../../Utils/password-math.validator';

declare var $:any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  public myForm: FormGroup;
  focus;
  focus1;
  focus2;
  test : Date = new Date();
  private toggleButton;
  private sidebarVisible: boolean;
  private nativeElement: Node;
  loginResult = true;
  errorsForm : any = {};
  loginWithErrors = false;
  user: any;

  constructor(
    private router   : Router,
    private element  : ElementRef,
    private spinner  : NgxSpinnerService,
    private formbuild: FormBuilder,
    private auth     : AuthService,
    public localService: LocalServiceService
  ) {
      this.nativeElement = element.nativeElement;
      this.sidebarVisible = false;
      this.user = new UsersModel();
   }

  ngOnInit(): void {

    this.checkFullPageBackgroundImage();
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    var navbar : HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    
    setTimeout(function(){
        // after 1000 ms we add the class animated to the login/register card
        $('.card').removeClass('card-hidden');
    }, 700);

    this.user = this.localService.getJsonValue('user');
    this.initForm();

  }

  ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
  }

  checkFullPageBackgroundImage(){
    var $page = $('.full-page');
    var image_src = $page.data('image');
    
    if(image_src !== undefined){
        var image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>'
        $page.append(image_container);
    }
  };

  initForm() {
    this.myForm = this.formbuild.group({
      email  : [{ value: this.user.email, disabled: true}, [Validators.required,Validators.pattern(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/), Validators.minLength(5)]],
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', [Validators.required]]
    },
    {
      validators: PasswordMath
    });
  }

  checkPasswordMath(): boolean {
    return  this.myForm.hasError('passwordMath')  &&
      this.myForm.get('newPassword').dirty &&
      this.myForm.get('confirmPassword').dirty;
  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = {
        id: this.user.id,
        email: this.user.email,
        estado: this.user.estado,
        perfil: this.user.perfil,
        password: this.myForm.value['newPassword'],
        restablecer_password: false
      };
      
      this.auth.updatePassword(data).then((res)=>{
        console.log(res);
        this.router.navigate(['/dashboard']);
      }).catch((err)=>{
          console.log('Ocurrio un error durante el registro!')
      })
    }
  }

}
