import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/core/services/auth/auth.service';

@Component({
  selector: 'app-parametros-contrasena',
  templateUrl: './parametros-contrasena.component.html',
  styleUrls: ['./parametros-contrasena.component.css']
})
export class ParametrosContrasenaComponent implements OnInit {
  myForm: FormGroup;
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild: FormBuilder,
    private authService: AuthService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.obtenerParametroContrasena()
  }
  initForm() {
    this.myForm = this.formbuild.group({
      numeros_minimos: [null, [Validators.required]],
      mayusculas_minimas: [null, [Validators.required]],
      minusculas_minimas: [null, [Validators.required]],
      simbolos_minimos: [null, [Validators.required]],
      longitud_minima: [null, [Validators.required]],
      contador_ultimos_passwords: [null, [Validators.required]],
      dias_validez_password: [null, [Validators.required]],
      maximo_intentos_login: [null, [Validators.required]],
    });
  }

  obtenerParametroContrasena() {
    this.authService.obtenerParametroContraseña(1)
      .then((res: any) => {
        this.myForm.patchValue({
          numeros_minimos: res.numeros_minimos,
          mayusculas_minimas: res.mayusculas_minimas,
          minusculas_minimas: res.minusculas_minimas,
          simbolos_minimos: res.simbolos_minimos,
          longitud_minima: res.longitud_minima,
          contador_ultimos_passwords: res.contador_ultimos_passwords,
          dias_validez_password: res.dias_validez_password,
          maximo_intentos_login: res.maximo_intentos_login,
        })
      })
  }
  onSubmit() {
    this.authService.guardarParametroContraseña(this.myForm.value, 1)
      .then(res => {
        this.successEmit.emit();
      })
  }
}
