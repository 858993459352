import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService,ROUTES } from '../services/auth/auth.service';
import { LocalServiceService } from '../services/local-service.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    
	constructor(
		public router : Router,
		public authService      : AuthService,
		public localService: LocalServiceService){
		
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
	{
		let route            = ROUTES.find(route => route.path === state.url)
		let proximaRutaLogin = state.url === '/login'
		// let proximaRutaReporte = state.url === '/login'
		let userExist        = !!(this.localService.getJsonValue('user'))

		// console.log(state.url)
		if(!userExist){
			this.router.navigate(['/login'])
			return false;
		}

		if(proximaRutaLogin && userExist){
			this.router.navigate(['/dashboard'])
			return false;
		}
		

		return true;
	}
	
}
	